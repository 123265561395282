import React, { useEffect, useRef } from 'react'

import { Player } from '@lottiefiles/react-lottie-player';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateMetaTags } from '../../../lib/withSEO';

const PasswordResetSuccess = () => {
    const navigate = useNavigate()
    const player = useRef('');

    const seoPasswordResetSuccess = {
        title: "Mot de passe réinitialisé | HooYia e-book store",
        description:
          "Votre mot de passe a été réinitialisé avec succès. Connectez-vous dès maintenant sur HooYia.",
        keywords: "mot de passe réinitialisé, hooyia, succès, compte",
        ogTitle: "Mot de passe réinitialisé | HooYia",
        ogDescription:
          "Votre compte est sécurisé. Connectez-vous pour continuer à profiter de HooYia e-book store.",
        ogImage: "https://www.e-hooyia.com/assets/password-reset-success-og.jpg",
        twitterTitle: "Mot de passe réinitialisé | HooYia",
        twitterDescription:
          "Votre mot de passe a bien été changé. Merci d'utiliser HooYia.",
        canonicalUrl: "https://www.e-hooyia.com/password-reset-success",
        noindex: false,
      };
      
    useEffect(() => {
        updateMetaTags(seoPasswordResetSuccess);
    }, [])

    const endAnimatiion = () => {
        player.current.setSeeker(120, false)
    }

    const { t } = useTranslation()

    return (
        <div className='email_verify'>
            <div className='container'>
                <div className='email_verify_loader'>
                    <Player
                        onEvent={event => {
                            if (event === 'complete') endAnimatiion()

                        }}
                        autoplay
                        direction={120}
                        ref={player}
                        src="https://assets4.lottiefiles.com/packages/lf20_bx4oomul.json"
                        style={{ height: '150px', width: '150px' }}
                    >
                        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
                </div>
                <p className='verify_email_text'>{ t("alert.password") }</p>
                <p className='continue_browsing'>
                    <Link to='/ebooks?auth=false'><BsArrowLeft />{ t("alerts.login") }</Link>
                </p>
            </div>
        </div>
    )
}

export default PasswordResetSuccess
