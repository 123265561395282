import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import './Product.css'
import './ProductDetails.css'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BsChevronDown, BsShield, BsShieldCheck, BsStarFill, BsAndroid2, BsFacebook, BsInstagram, BsMessenger, BsTwitter, BsWhatsapp } from 'react-icons/bs'
import { IoLocationOutline } from 'react-icons/io5'

import Image from '../../Assets/img/41pOKvNMdnL._SX355_BO1,204,203,200_.jpg'
import Off from '../Addons/Off'
import Stars from '../Addons/Stars'
import Rating from '../Addons/Rating'
import { getRequest } from '../../Resquest'
import Popup from '../Addons/Popup'
import useLocalStorage from '../lib/UseLocalStorage'
import { CartContext } from '../../Context/CartContext'
import { WishListContext } from '../../Context/WishListContext'
import PopupAddToCart from '../Addons/PopupAddToCart'
import PopupAddToAlert from '../Addons/PopupAddToAlert'
import { useTranslation } from 'react-i18next'
import { HiChevronRight } from 'react-icons/hi2'
import { Loader } from '../Checkout/Checkout'
import ProductGrid from './ProductGrid'
import { setMetaDescription, tabTitle } from '../..'
import { updateMetaTags } from '../../lib/withSEO'
const ProductDetails = ({ category_list }) => {
	const { id } = useParams()
	const [user, setuser] = useLocalStorage('user');
	const [ activeLink, setActiveLink ] = useState('#product-details');
	const [ebook, setEbook] = useState({});
	const [reviews, setReviews] = useState([]);
	const [cartAdded, setCartAdded] = useState(false);
	const [cartList, setCartList] = useContext(CartContext);
	const [wishList, setWishList] = useContext(WishListContext);
	const [wishListAdded, setWishListAdded] = useState(false);
	const [wishListRemoved, setWishListRemoved] = useState(false);
	const [loginAlert, setLoginAlert] = useState(false);
	const [loadingRecommendations, setLoadingRecommendations] = useState(true);
	const [recommendations, setRecommendations] = useState([]);
	const [isloading, setIsloading] = useState(false);

	
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	// tabTitle(`${ebook.name} - HooYia book`)
	const seoProductDetails = {
		title: "Détails du produit | HooYia e-book store",
		description:
		  "Consultez les informations détaillées de ce e-book et laissez-vous convaincre par son contenu et ses avis.",
		keywords: "détails, e-book, produit, hooyia, lecture, achat",
		ogTitle: "Détails du produit | HooYia",
		ogDescription:
		  "Accédez à toutes les informations et avis concernant ce e-book sur HooYia e-book store.",
		ogImage: "https://www.e-hooyia.com/assets/product-details-og.jpg",
		twitterTitle: "Détails du produit | HooYia",
		twitterDescription:
		  "Découvrez tous les détails de ce e-book et faites le bon choix sur HooYia.",
		canonicalUrl: "https://www.e-hooyia.com/ebooks/:id",
		noindex: false,
	  };
	useLayoutEffect(() => {
		updateMetaTags(seoProductDetails);
		loadEbookData()
		.then( requestRating() )
		.then(() => fetchRecommendations());
	}, [window.location.pathname]);
	
	// useLayoutEffect(() => {
	// 	if (ebook?.category) {
	// 		fetchRecommendations();
	// 	}
	// }, [ebook]);
	
	// load selected ebbok datas
	const loadEbookData = async () => {
		try {
			setIsloading(true);
			const response = await getRequest(`/public/ebook/display/${id}/`);
			if (response.status === 200) {
				saveData(response.data);
			}
		} catch (error) {
			setIsloading(false);
		}
	};

	const filterBookInRecommendations = (recommendationsArray) => {
		const currentBookSlug = location.pathname.split("ebooks/")[1];
		const filteredRecommendations = recommendationsArray.filter(
			(book) => book?.slug !== currentBookSlug
		);
		setRecommendations(filteredRecommendations);
	};

	
	const fetchRecommendations = async () => {
		const limit = 4;
	
		try {
			const response = await getRequest(
				`/public/ebook/display/?categories=${ebook?.category}&limit=${limit}`
			);
	
			if (response.status === 200) {
				let initialRecommendations = response.data.results || [];
	
				// If initial recommendations are less than the limit, fetch more
				if (initialRecommendations.length < limit) {
					const additionalRecommendations = await fetchMoreRecommendations(limit);
	
					initialRecommendations = mergeFixedSizeArrays(
						initialRecommendations,
						additionalRecommendations,
						limit
					);
				}
	
				filterBookInRecommendations(initialRecommendations);
			}
		} catch (error) {
		} finally {
			setLoadingRecommendations(false);
		}
	};
	
	const fetchMoreRecommendations = async (limit) => {
		try {
			const offset = Math.round(Math.random() * 10);
			const response = await getRequest(
				`/public/ebook/display/?limit=${limit * 2}&offset=${offset}`
			);
			return response.status === 200 ? response.data.results || [] : [];
		} catch (error) {
			return [];
		}
	};
	
	const mergeFixedSizeArrays = (initialRecommendations, moreRecommendations, limit) => {
		
		if (!Array.isArray(initialRecommendations) || !Array.isArray(moreRecommendations)) {
			return [];
		}
	
		const uniqueMoreRecommendations = moreRecommendations.filter(
			(book) => !initialRecommendations.some((rec) => rec.slug === book.slug)
		);
	
		return [
			...initialRecommendations,
			...uniqueMoreRecommendations.slice(0, limit - initialRecommendations.length),
		];
	};
	

	const saveData = (data) => {
		setEbook(data)
		setIsloading(false)
	}

	const requestRating = async () => {
		const response = await getRequest(`/public/ebook/review/?slug=${id}/`)
		if(response.statusText === 'OK'){
			setReviews(response.data.results)
		}
	}


	const removePopup = () => {
		setWishListAdded(false)
		setCartAdded(false)
		setWishListRemoved(false)
	}


	const removeAlertBox = () => {
		setLoginAlert(false)
	}


	const animateCartAdded = () => {
		setCartAdded(true)
		setTimeout(() => {
			setCartAdded(false)
		}, 5000)
	}

	
	const animateWishListRemoved = () => {
		setWishListRemoved(true)
		setTimeout(() => {
			setWishListRemoved(false)
		}, 3000)
	}


	const animateWishListAdded = () => {
		setWishListAdded(true)
		setTimeout(() => {
			setWishListAdded(false)
		}, 3000);
	}


	const wishListChanged = () => {
		if(!user){
			setLoginAlert(true)
		}else{

			if (inList) {
				removeFromList()
				animateWishListRemoved()
			} else {
				addToList()
				animateWishListAdded()
			} 
		}
	}


	const inCart = cartList && cartList.filter(book => book.slug === id).length > 0 ? true : false
	const inList = wishList && wishList.filter(book => book.slug === id).length > 0 ? true : false


	const addToCart = () => {
		!inCart && setCartList(cartList => [...cartList, ebook])
		animateCartAdded()
	}


	const removeFromList = () => {
		setWishList(
			wishList.filter(item => item.slug !== id)
		)
	}


	const addToList = () => {
		setWishList(
			[...wishList, ebook]
		)
	}


	const buyBook = () => {
		if(!user?.user){
			setLoginAlert(true)
		}else {
			!inCart && setCartList(cartList => [...cartList, ebook])
			navigate('/cart')
		}
	}

	useEffect(() => {
		const bookDescription = `${ebook?.description} - HooYia`
		const ogBookDescription = `${ebook?.description} - HooYia`
		
		setMetaDescription(bookDescription, ogBookDescription);
		
		return () => {
		  setMetaDescription('', '');
		};
	}, []);


	return (
		<div className='product_details_page container'>
			<div className='product_detail_top_grid'>
				<div className='images'>
					{
						isloading ?
						<div className='imgMain loading_skeleton' />
						:
						(
						<>
							<div className='imgMain'>
								{ebook?.cover && <img src={ebook?.cover} alt="product name" />}
							</div>
							<div className='subImages'>
							</div>
						</>
						)
					}
				</div>
				<div className='content'>
					<div className="header"><h2 className='product_the_title'>{ ebook.name }</h2></div>
					<div className='description'>{ebook?.description}</div>
					<div className='price_dis'>
						<p>
							<span className='price priceB'>XAF {ebook.price}</span>
							<span className='price priceOld'>XAF {ebook.discount_percentage > 0 ? parseInt(ebook.price) + (ebook.discount_percentage/100) * ebook.price:ebook.price}</span>
						</p>
						<p><Off discount={ebook.discount_percentage} /> </p>
					</div>
					<div className='buttons'>
						<button onClick={ buyBook } className='buyBtn'>{ t("product_detail.buy") }</button>
						<button onClick={addToCart} className='buyBtn addCartbtn'>{ t("product_detail.cart") }</button>
						<button onClick={wishListChanged} className='wishlistBtn buyBtn'>{ t("product_detail.wish_list") }</button>
					</div>
					<div className='shipping_talk'>
						<div className='iconB2'>
							<BsShieldCheck />
						</div>
						<div className='shipping_desc'>
							<p>{ t("product_detail.buy_prot") }</p>
							<span>{ t("product_detail.buy_prot_desc") }</span>
						</div>
					</div>
					<div className='share_product_links'>
						<div className='text'>{ t("product_detail.share") }</div>
						<div className='icon'>
							<span className='icon'> <a href="http://www.facebook.com/"> <BsFacebook /> </a></span>
							<span className='icon'> <a href="http://www.twitter.com/"><BsTwitter /> </a></span>
							<span className='icon'> <a href="http://www.instagram.com/"><BsInstagram /> </a></span>
							<span className='icon'> <a href="http://www.messager.com/"><BsMessenger /> </a></span>
							<span className='icon'> <a href="http://www.wa.me/?message='I will like to share with you this incredible item '/"><BsWhatsapp /> </a></span>
						</div>
					</div>

				</div>
				<div className='side_comments'>

				</div>
			</div>
			<div   className='recommendation_and_conclusion'>
				<div className='pined_category'>
					<div className='title'>{ t("product_detail.category") }</div>
					<ul className='category_list'>
						{
							category_list?.map(cat => (
								<li key={ cat.pk } className='category_item'>
									<a onClick={()=> navigate('/ebooks?category='+ cat?.name, {state: {"dir": cat?.name}})} >{ cat?.name }{ cat?.children.length > 1 && <span className='cat_dropdown_indicator'><span><HiChevronRight /></span></span> }</a>
									{
										cat?.children.length > 1 && (
											<div className='drop_cat_list'>
												{
												cat?.children?.map(child_cat => (
													<li key={child_cat } className='category_item'>
														<a onClick={()=> navigate(`/ebooks?category=${cat?.name}-${child_cat}`, {state: {"dir": cat?.name}})} >{ child_cat }</a>
													</li>
												))
												}
											</div>
										)

									}
								</li>
							))
						}
					</ul>
				</div>
				<div className='desc_right'>
					<div className='productGridItem'>
						<div className='detail-review'>
							<a href='#product-details' className={`productReviewLink ${activeLink === '#product-details' ? 'active' : ''}`}
							onClick={() => {setActiveLink('#product-details')}}>{ t("product_detail.prod_desc_title") }</a>
							<a href='#product-review' className={`productReviewLink ${activeLink === '#product-review' ? 'active' : ''}`}
							   onClick={() => {setActiveLink('#product-review')}}>{ t("product_detail.prod_review_title") }(2)</a>
						</div>
						<section className="itemDescription" id='product-details'>
							<h4 className="title">{ t("product_detail.prod_desc_title") }</h4>
							<p>{ebook.description} </p>
							<ul className='productDetailList'>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.author") }</p>
									<p className='value'>{ebook.author}</p>
								</li>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.category") }</p>
									<p className='value'>
										<span className='top_selling_tag commic_tag'>{ebook?.category}</span>
									</p>
								</li>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.price") }</p>
									<p className='value'>{ebook?.price}</p>
								</li>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.type") }</p>
									<p className='value'>{ t("product_detail.ebook") }</p>
								</li>
							</ul>
						</section>

						{/* ============ REVIEWS SECTION========== */}
						<section className="itemDescription reviews" id='product-review'>
							<h4 className="title">{t("product_detail.reviews")}</h4>
							<Rating />
							{
								reviews && reviews.length > 0 ? (
									reviews.map((rev, index) => (
										<div key={index} className='review'>
											<div className='profile'>
												<div className='profilePic'>
												</div>
											</div>
											<div className='review-content'>
												<Stars value={5} />
												<p>{rev}</p>
											</div>
										</div>
									))
								) : (
									<p style={{ fontWeight: 400, fontSize: 'medium' }}>0</p>
								)
							}


						</section>

						{/* ============ RECOMMENDATION ============ */}
					</div>
					<section className='recommendation' id='recommendation'>
						<h4 className="title">{t("product_detail.recommandations")}</h4>
						<span>({ t("product_detail.recommandation_desc") }) </span>
						<div className='recommendation_items'>
							<div className='loadingrecommendations'>
							{
								loadingRecommendations ?
								(
										<Loader />
										)
										:
										<div className='recommendation_overflow'>
											<ProductGrid products={recommendations} />
										</div>
									}
							</div>
						</div>
					</section>
				</div>
			</div>
		<PopupAddToCart handleRemoveModal={removePopup} showPopup={cartAdded} cart={true} text={ t("modals.add_to_cart.text") } />
		<PopupAddToAlert book_id={ ebook?.slug } handleRemoveModal={removeAlertBox} showPopup={loginAlert} />
		<Popup handleRemoveModal={removePopup} showPopup={wishListAdded} text={ t("modals.add_to_wishlist.text") } />
		<Popup handleRemoveModal={removePopup} showPopup={wishListRemoved} text={ t("modals.rm_to_wishlist.texts") } />
	</div>
	)
}

export default ProductDetails
