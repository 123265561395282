import React, { useContext, useState, useRef, useEffect } from 'react'
import axios from 'axios';
import './Authenticate.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsGift } from 'react-icons/bs'
import { MdChangeCircle, MdOutlineEmail, MdOutlineWhatsapp } from 'react-icons/md'
import FacebookButton from './OAuth/FaceBookButton'
import GoogleButton from './OAuth/GoogleButton'
import { auth, getExternalRequest } from '../../../Resquest';
import { UserContext } from '../../../Context/AuthContext';
import {ReactComponent as ThreeDots} from '../../../Assets/Loaders/ThreeDots.svg'
import LinkedInButton from './OAuth/LinkedInButton';
import { ReactComponent as LoginSvg } from '../../../Assets/img/login_svg.svg';
import googleLogo from './OAuth/googleLogo.png'
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import useLocalStorage from '../../lib/UseLocalStorage';
import { handleHTTPResponse } from '../../../Resquest';
import { updateMetaTags } from '../../../lib/withSEO';

const Login = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useContext(UserContext);
    const [showPassword, setShowPassword] = useState(false);
    const [redirectUrl, setRedirectUrl] = useLocalStorage('redirectUrl');
    const [verificationMethod, setVerificationMethod] = useState('e');
    const [confirmVerificationMethodModal, setConfirmVerificationMethodModal] = useState(true);

    // New states for country selection
    const [countryString, setCountryString] = useState('');
    const [show_country_dropdown, setShow_country_dropdown] = useState(false);
    const [country_search_response, setCountry_search_response] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [countryFlag, setCountryFlag] = useState(null);
    const [countryCode, setCountryCode] = useState('');
    const phoneNumberRef = useRef(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phoneVerified = queryParams.get('phone_verified');

    const navigate = useNavigate();
    const { t } = useTranslation();

    const userData = {
        email: verificationMethod === 'e' ? email.replace(/\s/g, '') : phone.replace(/\s/g, ''),
        password: password,
        verification_method: verificationMethod
    };

    // Country selection functions
    const fetchCountries = async () => {
        if (countryList.length < 1) {
            const res = await getExternalRequest("https://restcountries.com/v3.1/all?fields=name,flags,code,idd");
            setCountry_search_response(res.data);
            setCountryList(res.data);
        }
    };

    const searchCountry = () => {
        let search_list = countryList.filter((country) => 
            country.name.common.toLowerCase().startsWith(countryString.toLowerCase())
        );
        setCountry_search_response(search_list);
    };

    const getCountryList = async(e) => {
        e.target.removeAttribute('readonly');
        setShow_country_dropdown(true);
        fetchCountries();
    };

    const saveCountry = (country) => {
        let code = country?.idd?.root;
        phoneNumberRef.current.focus();
        if(country?.idd?.suffixes.length === 1){
            let suffix = country?.idd?.suffixes[0];
            code = code + suffix + ' ';
        }
        setCountryFlag(country?.flags?.svg);
        setCountryCode(code);
        setPhone(code);
        setCountryString(country?.name?.common);
        setShow_country_dropdown(false);
    };

    useEffect(() => {
        fetchCountries();
    }, []);

	const seoLogin = {
		title: "Connexion à votre compte e-hooyia| HooYia e-book store",
		description:
		  "Connectez-vous à votre compte HooYia pour accéder à vos e-books et gérer vos commandes.",
		keywords: "connexion, login, hooyia, e-book, compte",
		ogTitle: "Connexion | HooYia",
		ogDescription:
		  "Accédez à votre compte et profitez de nos services sur HooYia e-book store.",
		ogImage: "https://www.e-hooyia.com/assets/login-og.jpg",
		twitterTitle: "Connexion | HooYia",
		twitterDescription:
		  "Connectez-vous sur HooYia pour gérer vos achats d'e-books en toute simplicité.",
		canonicalUrl: "https://www.e-hooyia.com/login",
		noindex: false,
	};

	useEffect(() => {
		updateMetaTags(seoLogin);
	}, []); 
	
	const responseMessage = (response) => {
	};
	const errorMessage = (error) => {
	};



    const sendForm = async () => {
        setIsLoading(true);
        const res = await auth('/auth/login', userData);
        if (res?.status === 200) {
            saveUser(res.data);
        } else {
            setIsLoading(false);
        }
        res.response?.status === 406 && alert(t("loginPage.invalidCredential"));
        res.response?.status === 401 && alert(t("loginPage.accountNotActive"));
        res.response?.status === 404 && alert(t("loginPage.accountNotFound"));
        res.response?.data?.detail && alert(t("loginPage.invalidCredential"));
        res.code === "ERR_NETWORK" && alert(t("loginPage.networkError"));
        handleHTTPResponse(res.response);
        
        setIsLoading(false);
    };

    const saveUser = (userResponse) => {
        redirect();
        setUser(userResponse);
    };

    const redirect = () => {
        window.location.href = redirectUrl || '/';
        setRedirectUrl('/');
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setConfirmVerificationMethodModal(false);
        sendForm();
    };

    const alert = (msg) => {
        setMessage(msg);
    };

    const clearForm = () => {
        setEmail('');
        setPhone('');
        setPassword('');
        setCountryString('');
        setCountryFlag(null);
    };

    return (
        <div className='auth_n'>
            <div className='authenticate'>
                <div className='authenticate__content'>
                    <div className='authenticate__content_container'>
                        <h3>{t("auth_title.login")}</h3>
                        
                        <form method="post" onSubmit={handleFormSubmit} autoComplete="off">
                            {phoneVerified === 'true' && <div className={`alert showAlert phoneMessage`}>{t("signupPage.phoneVerifiedMessage")}</div>}
                            <p style={{ marginBottom: '1rem'}}>{t("loginPage.login_message")}</p>
                            
                            <div className={`alert ${message ? 'showAlert' : ''}`}>{message}</div>
                            
                            {verificationMethod === 'e' ? (
                                <div className="inputField">
                                    <input 
                                        autoComplete="new-password" 
                                        autoCorrect="off" 
                                        autoCapitalize="off" 
                                        spellCheck="false" 
                                        readonly 
                                        onFocus={e => e.target.removeAttribute('readonly')} 
                                        type="text" 
                                        onChange={e => setEmail(e.target.value)} 
                                        value={email} 
                                        required 
                                    />
                                    <label className="label">
                                        <span className="labelContent">{t('signupPage.email')}</span>
                                    </label>
                                </div>
                            ) : (
                                <div className='auth_input_flex'>
                                    <div className='auth_country_wrapper'>
                                        <div className="inputField country_list_auth">
                                            <input 
                                                type="text" 
                                                onFocus={getCountryList} 
                                                onChange={e => setCountryString(e.target.value)} 
                                                onBlur={() => setTimeout(() => setShow_country_dropdown(false), 200)} 
                                                onKeyUp={searchCountry}
                                                autoComplete="new-password" 
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                value={countryString} 
                                                readonly
                                                required 
                                            />
                                            <label className="label">
                                                <span className="labelContent">{t('dashboard_edit_form.country')}</span>
                                            </label>
                                        </div>
                                        {show_country_dropdown && (
                                            <div className='country_list_suggestions'>
                                                {country_search_response.map(country => (
                                                    <div 
                                                        key={country.name.common}
                                                        className='country_list_item' 
                                                        onMouseDown={() => saveCountry(country)}
                                                    >
                                                        <span className="icon">
                                                            <img src={country.flags.svg} alt={country.name.common} />
                                                        </span>
                                                        <span className="countryname">{country.name.common}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <div className="inputField">
                                        <input 
                                            ref={phoneNumberRef}
                                            type="text" 
                                            autoComplete="new-password" 
                                            onChange={e => setPhone(e.target.value)} 
                                            value={phone} 
                                            required 
                                        />
                                        <label className="label">
                                            <span className="labelContent">{t('signupPage.phone')}</span>
                                        </label>
                                        {countryFlag && (
                                            <span className='preview_country_icon'>
                                                <img src={countryFlag} alt={countryCode} />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="inputField">
                                <input 
                                    autoComplete="new-password" 
                                    autoCorrect="off" 
                                    autoCapitalize="off" 
                                    spellCheck="false" 
                                    readonly 
                                    onFocus={e => e.target.removeAttribute('readonly')} 
                                    type={showPassword ? "text": "password"} 
                                    onChange={e => setPassword(e.target.value)} 
                                    value={password} 
                                    required 
                                />
                                <label className="label">
                                    <span className="labelContent">{t('signupPage.password')}</span>
                                </label>
                                <span onClick={() => setShowPassword(!showPassword)} className='show_password_icon'>
                                    {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                                </span>
                            </div>

                            <div className="buttonWrapper signup">
                                <button type="submit" className="submitBtn">
                                    <span className='buttonText'>{t('signupPage.login')}</span>
                                    {isLoading && <span className='loadingButton'><ThreeDots /></span>}
                                </button>
                                <button 
                                    className="submitBtn changeMethodLogin" 
                                    title='change authentication method between email and phone' 
                                    type="button" 
                                    onClick={() => setConfirmVerificationMethodModal(true)}
                                >
                                    {t("signupPage.change_method")}
                                    <span><MdChangeCircle /></span>
                                </button>
                            </div>

                            <p>{t('loginPage.have_an_account')}<Link to='/register' className='link'>{t('loginPage.signup')}</Link></p>
                            <p>{t('loginPage.forgot_password')} <Link to='/forgot-password' className='link forgot_password_link_n'>{t('loginPage.click_here')}</Link></p>

                            <div className={`select_confirm_user_method ${confirmVerificationMethodModal ? 'show_verification_modal_auth': ''}`}>
                                <div className='confirm_method_content'>
                                    <p className='confimation_title'>{t("signupPage.selectMethod")}</p>
                                    <div className='confirmation_methods_flex'>
                                        <div 
                                            onClick={() => setVerificationMethod('e')} 
                                            className={`account_confirm_method ${verificationMethod === 'e' ? 'selected_verification_method': ''}`}
                                        >
                                            <div className='method_icon'>
                                                <MdOutlineEmail />
                                            </div>
                                            <p>Email</p>
                                        </div>
                                        <div 
                                            onClick={() => setVerificationMethod('w')} 
                                            className={`account_confirm_method ${verificationMethod === 'w' ? 'selected_verification_method': ''}`}
                                        >
                                            <div className='method_icon'>
                                                <MdOutlineWhatsapp />
                                            </div>
                                            <p>WhatsApp</p>
                                        </div>
                                    </div>
                                    <button 
                                        type="button" 
                                        onClick={() => setConfirmVerificationMethodModal(false)} 
                                        className="submitBtn"
                                    >
                                        <span className='buttonText'>{t("signupPage.select_method")}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='signup_convince'>
                        <div className='convince_item'>
                            <div className='title'>
                                <span className='iconRegister'><BsGift /></span>
                                <span>Features</span>
                            </div>
                            <p>{t('loginPage.comment_two')}</p>
                        </div>
                        <div className='convince_item'>
                            <div className='svg_login login_page_specific'>
                                <LoginSvg />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;