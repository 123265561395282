import React from 'react';
import { Settings2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { tabTitle } from '../..';
import './settings.css';

const Settings = ({ user }) => {
  const { t } = useTranslation();
  
  // Set page title
  tabTitle(`${user?.user?.first_name} ${user?.user?.last_name} parametre`);

  return (
    <div className="settings-coming-soon">
      <div className="settings-content">
        <div className="settings-icon">
          <Settings2 size={64} />
        </div>
        
        <h2 className="settings-title">
          {t("settings.title")}
        </h2>
        
        <div className="settings-message">
          <p className="message-primary">
		  	This feature is under development and will be available soon.
          </p>
          
          <p className="message-secondary">
		  	We work hard to give you a complete customization experience. Check back soon for the new settings!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Settings;