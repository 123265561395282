import React, { useState, useEffect, useRef } from "react";
import { Document, Page } from '@react-pdf/renderer';
import { ChevronLeft, ChevronRight, Menu, Download } from "lucide-react";
import "./PdfBook.css";
import { useParams } from "react-router-dom";
import { pdfjs } from 'react-pdf';

const PDFViewer = () => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageScale, setPageScale] = useState(1.0);
  const [showThumbnails, setShowThumbnails] = useState(true);
  const [thumbnails, setThumbnails] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [containerWidth, setContainerWidth] = useState(null);
  const containerRef = useRef(null);
  const { doc } = useParams();
  const decodedDoc = decodeURIComponent(doc);

  console.log('------------->>>>>>>>>>>> ', decodedDoc);

  const file = decodedDoc;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const onDocumentLoadError = (error) => {
    setError(error);
    setLoading(false);
  };

  useEffect(() => {
    const updateSize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    const loadThumbnails = async () => {
      const loadingTask = pdfjs.getDocument(file);
      const pdf = await loadingTask.promise;
      setNumPages(pdf.numPages);

      const thumbnailsArray = [];
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 0.2 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = { canvasContext: context, viewport };
        await page.render(renderContext).promise;
        thumbnailsArray.push(canvas.toDataURL());
      }
      setThumbnails(thumbnailsArray);
    };

    loadThumbnails();
  }, [file]);

  const handleScaleChange = (e) => {
    const newScale = parseFloat(e.target.value);
    setPageScale(newScale);

    // Ajuster la largeur du conteneur en fonction du zoom
    if (containerRef.current) {
      const newWidth = containerRef.current.clientWidth * newScale;
      setContainerWidth(newWidth);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = file;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, numPages));

  return (
    <div className="pdf-viewer-container">
      {showThumbnails && (
        <div className="thumbnails-container">
          {thumbnails.map((thumbnail, index) => (
            <img
              key={index}
              src={thumbnail}
              alt={`Page ${index + 1}`}
              className={`thumbnail ${currentPage === index + 1 ? "active" : ""}`}
              onClick={() => setCurrentPage(index + 1)}
            />
          ))}
        </div>
      )}

      <div className="document-container">
        {!isMobile && (
          <div className="navigation-bar">
            <button onClick={() => setShowThumbnails(!showThumbnails)} className="nav-button">
              <Menu />
            </button>
            <button onClick={handleDownload} className="nav-button">
              <Download />
            </button>
            <button onClick={goToPreviousPage} className="nav-button" disabled={currentPage === 1}>
              <ChevronLeft />
            </button>
            <span className="page-info">Page {currentPage} / {numPages}</span>
            <button onClick={goToNextPage} className="nav-button" disabled={currentPage === numPages}>
              <ChevronRight />
            </button>
            <select onChange={handleScaleChange} value={pageScale}>
              <option value="0.5">50%</option>
              <option value="0.75">75%</option>
              <option value="1">100%</option>
              <option value="1.25">125%</option>
              <option value="1.5">150%</option>
              <option value="1.75">175%</option>
              <option value="2">200%</option>
            </select>
          </div>
        )}
        
        <div 
          className="pdf-document-wrapper" 
          ref={containerRef}
          style={{ width: containerWidth ? `${containerWidth}px` : 'auto' }}
        >
          {loading && <div>Loading PDF...</div>}
          {error && <div>Error loading PDF: {error.message}</div>}
          
          <Document 
            file={file} 
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            loading={<div>Loading PDF...</div>}
          >
            {!loading && (
              <Page 
                pageNumber={currentPage} 
                scale={isMobile ? 0.8 : pageScale} 
                className="pdf-page" 
              />
            )}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;