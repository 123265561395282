import React, { useState, useEffect, useLayoutEffect } from 'react';
import './Profile.css';
import './StoreProfile.css';
import { getRequest } from '../../Resquest';
import ProductGrid from '../Products/ProductGrid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { tabTitle } from '../..';
import facebook from '../../Assets/img/facebook.png';
import whatsapp from '../../Assets/img/whatsapp.png';
import twitter from '../../Assets/img/twitter.png';
import linkedin from '../../Assets/img/linkedin.png';
import { FaLink } from 'react-icons/fa';
import { AnimatePresence } from 'framer-motion';
import { CopyAlert } from '../SELLER/Dashboard/Components/ListBooks';
import { AWSbaseURL } from '../../Resquest';
import { withSEO } from '../../lib/withSEO';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import { NoBookSection } from '../Home/Home'; 

const StoreProfile = () => {
        const [isLoading, setIsLoading] = useState(false)
        const [userData, setUserData] = useState({});
        const [isloading, setIsloading] = useState(false);
        const [ebooks, setEbooks] = useState([]);
        const [originalEbooks, setOriginalEbooks] = useState([]);
        const [fname, setFname] = useState('');
        const [lname, setLname] = useState('');
        const [searchString, setSearchString] = useState('');
        const [lang, setLang] = useState('en');
        const [copiedStore, setCopiedStore] = useState(false);
        const [slug, setSlug] = useState('');
        const [isResponsse, setIsResponse] = useState(false);
        const [ storeNotFound, setStoreNotFound ] = useState(false);
        const [shareLink, setShareLink] = useState("https://www.e-hooyia.com/me/" + window.location.pathname.split('/me/')[1]);
      
        
        useEffect(() => {
                const user = localStorage.getItem('user');
                if (user) {    
                        localStorage.removeItem('user');
                        localStorage.removeItem('redirectUrl');
                        // window.location.reload();
                        navigate(window.location.pathname, { state : { "logout": true } });
                        console.log("user disconnected successfully");
                }
 
        }, []);

        useEffect(() => {
          const fetchData = async () => {
            setIsloading(true);
            const username = window.location.pathname.split('/me/')[1];
            const endpoint = `/ebook-store/${username}`;
            try {
              const res = await getRequest(endpoint);
              res && setIsResponse(true);
              if (res.status === 200) {
                saveResponse(res.data);
              }else{
                setStoreNotFound(true);
              }
            } catch (error) {
              console.error("Failed to fetch data:", error);
              setStoreNotFound(true);
            } finally {
              setIsloading(false);
            }
          };
      
          fetchData();
          getLang();
        }, []);
      
        useEffect(() => {
          const filterSearch = () => {
            if (searchString) {
              setEbooks(
                originalEbooks.filter((book) =>
                  book.name.toLowerCase().includes(searchString.toLowerCase())
                )
              );
            } else {
              setEbooks(originalEbooks);
            }
          };
          filterSearch();
        }, [searchString, originalEbooks]);
      
        const getLang = () => {
          setLang(i18next.language);
        };
      
        const saveUserData = (user) => {
          setFname(user?.first_name);
          setLname(user?.last_name);
        };
      
        const saveResponse = (data) => {
          saveUserData(data.user);
          setUserData(data);
          setEbooks(data?.ebooks);
          setOriginalEbooks(data?.ebooks);
          console.log("User data fetched successfully:", data);
        };
      
        const copyLink = () => {
          const link = "https://www.e-hooyia.com/me/" + slug;
          navigator.clipboard.writeText(link);
          setCopiedStore(true);
          setTimeout(() => {
            setCopiedStore(false);
          }, 3000);
        };
      
        const shareText = "Come and visit the " + fname + "'s shop on Hooyia and treat yourself with to the best trends in the writing world !";
      
        const { t } = useTranslation();
        const navigate = useNavigate();

  return (
        <div className='personal_shop_space'>
                <div className="top-container">
                        <div className="containers">
                        <div className="seller_header">
                                {
                                        userData?.user
                                        ?
                                        <>
                                                <div className="bloc_image">
                                                        {
                                                                userData?.user?.pp
                                                                ?
                                                                <img className="profile_image" src={(userData?.user?.pp)} alt="Profile image"/>
                                                                :
                                                                <p className='profile_image'>{ (fname).charAt(0)} { (lname).charAt(0)  }</p>
                                                        }
                                                </div>
                                                <div className="infos">
                                                        {
                                                                <>
                                                                        <p className="welcome">
                                                                               {
                                                                                        lang === 'fr'
                                                                                        ?
                                                                                        (<>Bienvenue dans la boutique HooYia de <span className="name">{fname} {lname}</span> !</>)
                                                                                        :(<>Welcome to <span className="name">{fname} {lname}</span>'s shop space !</> )
                                                                                        
                                                                                        
                                                                               }
                                                                        </p>
                                                                        <p className='seller_address'>{t('store_profile.address')} { userData?.user?.city + ',' ?  userData?.user?.city : ''}  { userData?.user?.country ?  userData?.user?.country : '----'  }</p>
                                                                        <p className="date">{userData?.user?.email}</p>
                                                                </>
                                                        }
                                                </div>
                                        </>
                                        :
                                        <div className='container flex_profile_head'>
                                                <h3>{ t("randoms.store_not_found") }</h3>
                                        </div>
                                }
                        </div>
                                <div className="about_and_stats">
                                        <div className={`bio_seller ${isloading ? 'loading_skeleton': ''}`}>
                                                <h2 className="title_about">{t('store_profile.about')} {fname} :</h2>
                                                <p className={`contain`}>
                                                         <blockquote>
                                                                {
                                                                userData?.user?.bio ?
                                                                <>
                                                                        <RiDoubleQuotesL/>
                                                                        <br/>
                                                                        <span> &nbsp; &nbsp; &nbsp; &nbsp;{userData?.user?.bio} &nbsp; </span>
                                                                        <br/>
                                                                        <RiDoubleQuotesR/>
                                                                </>
                                                                 : '--------------'
                                                                 }
                                                          </blockquote>
                                                </p>
                                        </div>
                                        <div className="expertise">
                                                <div className="book_shop">
                                                        <span className="item_title">{t('store_profile.books')} </span><span className="item_value">{ebooks ? ebooks.length : '----'} &nbsp;&nbsp; {t('store_profile.items')} </span>
                                                </div>
                                                <div className="book_shop">
                                                        <span className="item_title">{t('store_profile.country')} </span> { <span className="item_value"> {userData?.user?.country} </span>}
                                                </div>
                                                <div className='social_media'>
                                                        <span className=" item_title follow_section">{t('store_profile.follow_me_on')} :</span> 
                                                        <div className='share_links'>
                                                                        <ul>
                                                                                <li className="d_flex_link">
                                                                                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + shareLink}  referrerPolicy='noreferrer' reel='noopener noreferrer' target="_blank">{ t("share.fb") }</a>
                                                                                        <img src={facebook} width={20} height={20}/>
                                                                                </li>
                                                                                <li className="d_flex_link">
                                                                                        <a href={`https://api.whatsapp.com/send?text=${shareText}%20${shareLink}`}  referrerPolicy='noreferrer' reel='noopener noreferrer' target="_blank">{ t("share.whatsapp") }</a>
                                                                                        <img src={whatsapp} width={30} height={30} margin={0} padding={-20}/>
                                                                                </li>
                                                                                <li className="d_flex_link">
                                                                                        <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + shareLink}  referrerPolicy='noreferrer' reel='noopener noreferrer' target="_blank">{ t("share.linkedin") }</a>
                                                                                        <img src={linkedin} width={22} height={22}/>
                                                                                </li>
                                                                                <li className="d_flex_link">
                                                                                        <a href={"https://twitter.com/intent/tweet?url=" + shareLink + "&text=" + shareText}  referrerPolicy='noreferrer' reel='noopener noreferrer' target="_blank">{t("share.tweeter")}</a>
                                                                                        <img src={twitter} width={20} height={20}/>
                                                                                </li>
                                                                                <li onClick={copyLink} className='copy_store'>
                                                                                        <span>{ t("share.copy") }</span>
                                                                                        <span><FaLink /></span>
                                                                                </li>
                                                                        </ul>
                                                                </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>

                {
                        userData?.user && (
                                <>
                                        <div className='profile_body'>
                                                <div className='container'>
                                                        <h3 className='book_collection_container' style={{ marginTop: '0px' }}>{ t("alerts.store_title") }</h3>
                                                        <div className='filter_store_wrapper'>
                                                                <span className='filter_heading filter_text'>{t('store_profile.filter')} </span>
                                                                <input type='text' disabled={ebooks.length <= 0 } className='filter_store_input filter_select' onChange={e => setSearchString(e.target.value)} value={searchString} placeholder='search...' />
                                                        </div>
                                                        {
                                                                ebooks.length > 0
                                                                ?
                                                                <ProductGrid products={ebooks} isloading={isloading} />
                                                        :
                                                                <NoBookSection/>
                                                        }
                                                </div>
                                        </div>
                                
                                </>
                        )
                }
                        <AnimatePresence
                                initial={false}
                                mode='wait'
                        >
                                { copiedStore && (
                                        <CopyAlert />
                                )}
                        </AnimatePresence>
        </div>
  );
};

export default StoreProfile;