import React, { useEffect, useState, lazy, Suspense } from 'react'
import NavSeller from '../Components/SELLER/Nav/NavSeller'
import Dashboard from '../Components/SELLER/Dashboard/Dashboard'
import Logout from '../Components/Auth/Logout';
import { Route, Routes } from 'react-router-dom';
// import Login from '../Components/Auth/Login';
import SellerProfile from '../Components/SELLER/Dashboard/SellerProfile/SellerProfile';
import SellerProfileEdit from '../Components/SELLER/Dashboard/SellerProfile/SellerProfileEdit';
import Chat from '../Components/mesages/Chat';
import UserProfile from '../Components/Profile/UserProfile';
import Settings from '../Components/Profile/Settings';
import BookCreate from '../Components/SELLER/Book/BookCreate';
import Discount from '../Components/SELLER/Discount/Discount';
import { getRequest } from '../Resquest';
import Earning from '../Components/SELLER/Earnings/Earning';
import InfoSwitchBuying from '../Components/SELLER/InfoSwitchBuying';
import BookDetails from '../Components/SELLER/Book/BookDetails';
import StoreProfile from '../Components/Profile/StoreProfile';
import SellerPrivacy from '../Components/SellerAccount/SellerPrivacy';
import Faq from '../Components/NON-USER/FAQ/Faq';
import Contact from '../Components/NON-USER/Contact/Contact';
import TransactionHistory from '../Components/SELLER/transactionHistory/TransactionHistory';
import RequestSubmitted from '../Components/NON-USER/Contact/RequestSubmitted';
import LazyLoader from '../Components/Addons/LazyLoader';
import { usePagination } from "../Context/PaginationContext";
import { withSEO } from '../lib/withSEO';
import Login from '../Components/NON-USER/Auth/Login';

const AppSeller = ({ user }) => {

	const SelllerStats = lazy(() => import('../Components/SELLER/Statistics/SelllerStats'))

	const getUserProfileSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: `${firstName}'s Profile | HooYia e-book store`,
		  description: `Découvrez le profil de ${firstName} sur HooYia, la plateforme de référence pour les e-books et les auteurs passionnés.`,
		  keywords: "profil, hooyia, e-book, auteur, lecteur, compte",
		  ogTitle: `${firstName}'s Profile | HooYia e-book store`,
		  ogDescription: `Apprenez un peu plus sur ${firstName} à travers son profil HooYia.`,
		  ogImage: "https://www.e-hooyia.com/assets/user-profile-og.jpg",
		  twitterTitle: `${firstName}'s Profile | HooYia`,
		  twitterDescription: `Découvrez le profil de ${firstName} sur HooYia e-book store.`,
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}`,
		  noindex: false,
		};
	  };
	  
	  // 2. Chat (Inbox)
	  const getChatSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Inbox | HooYia e-book store",
		  description:
			"Consultez vos messages et restez en contact avec vos lecteurs et auteurs sur HooYia.",
		  keywords: "inbox, messages, hooyia, e-book, communication",
		  ogTitle: "Inbox | HooYia e-book store",
		  ogDescription:
			"Gérez vos conversations et restez connecté avec la communauté HooYia.",
		  ogImage: "https://www.e-hooyia.com/assets/inbox-og.jpg",
		  twitterTitle: "Inbox | HooYia",
		  twitterDescription:
			"Vérifiez vos messages et discutez avec vos contacts sur HooYia e-book store.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/inbox`,
		  noindex: false,
		};
	  };
	  
	  // 3. Seller Book Create (pour la route : /{first_name}/book)
	  const getSellerBookCreateSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Créer un e-book | HooYia e-book store",
		  description:
			"Mettez en vente votre e-book sur HooYia et atteignez une audience de passionnés de lecture.",
		  keywords: "créer e-book, publication, hooyia, vente, auteur",
		  ogTitle: "Créer et publier votre e-book | HooYia",
		  ogDescription:
			"Transformez votre manuscrit en succès en publiant votre e-book sur HooYia.",
		  ogImage: "https://www.e-hooyia.com/assets/seller-create-book-og.jpg",
		  twitterTitle: "Créer un e-book | HooYia",
		  twitterDescription:
			"Publiez votre e-book et partagez votre passion sur HooYia, la plateforme des auteurs.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/book`,
		  noindex: false,
		};
	  };
	  
	  // 4. Settings
	  const getSettingsSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Paramètres du compte | HooYia e-book store",
		  description:
			"Modifiez vos préférences et gérez votre compte en toute simplicité sur HooYia.",
		  keywords: "paramètres, compte, hooyia, e-book, réglages",
		  ogTitle: "Paramètres du compte | HooYia",
		  ogDescription:
			"Accédez aux paramètres de votre compte et personnalisez votre expérience sur HooYia.",
		  ogImage: "https://www.e-hooyia.com/assets/settings-og.jpg",
		  twitterTitle: "Paramètres du compte | HooYia",
		  twitterDescription:
			"Gérez et personnalisez votre compte sur HooYia e-book store en quelques clics.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/settings`,
		  noindex: false,
		};
	  };
	  
	  // 5. Seller Profile
	  const getSellerProfileSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Mon profil vendeur | HooYia e-book store",
		  description:
			"Découvrez votre espace vendeur sur HooYia, où vous pouvez gérer vos e-books et interagir avec vos clients.",
		  keywords: "profil vendeur, hooyia, e-book, auteur, boutique",
		  ogTitle: "Profil vendeur | HooYia",
		  ogDescription:
			"Gérez votre boutique d'e-books et connectez-vous avec votre audience sur HooYia.",
		  ogImage: "https://www.e-hooyia.com/assets/seller-profile-og.jpg",
		  twitterTitle: "Profil vendeur | HooYia",
		  twitterDescription:
			"Votre espace vendeur pour gérer vos e-books sur HooYia e-book store.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/profile`,
		  noindex: false,
		};
	  };
	  
	  // 6. Seller Profile Edit
	  const getSellerProfileEditSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Modifier mon profil | HooYia e-book store",
		  description:
			"Personnalisez et mettez à jour votre profil vendeur sur HooYia pour mieux refléter votre univers d'e-book.",
		  keywords: "modifier profil, hooyia, e-book, vendeur, éditer",
		  ogTitle: "Modifier mon profil | HooYia",
		  ogDescription:
			"Accédez à votre espace de modification de profil et ajustez vos informations personnelles sur HooYia.",
		  ogImage: "https://www.e-hooyia.com/assets/seller-profile-edit-og.jpg",
		  twitterTitle: "Modifier mon profil | HooYia",
		  twitterDescription:
			"Mettez à jour votre profil vendeur et améliorez votre présence sur HooYia e-book store.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/profile`,
		  noindex: false,
		};
	  };
	  
	  // 7. Earnings
	  const getEarningSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Mes gains | HooYia e-book store",
		  description:
			"Consultez vos revenus générés par la vente de vos e-books et suivez vos performances sur HooYia.",
		  keywords: "gains, revenus, hooyia, e-book, ventes",
		  ogTitle: "Mes gains | HooYia",
		  ogDescription:
			"Visualisez en temps réel les revenus issus de la vente de vos e-books sur HooYia.",
		  ogImage: "https://www.e-hooyia.com/assets/earnings-og.jpg",
		  twitterTitle: "Mes gains | HooYia",
		  twitterDescription:
			"Suivez vos revenus et performances sur HooYia e-book store.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/earnings`,
		  noindex: false,
		};
	  };
	  
	  // 8. Transaction History
	  const getTransactionSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Historique des transactions | HooYia e-book store",
		  description:
			"Accédez à l'historique complet de vos transactions et gérez vos opérations en toute transparence sur HooYia.",
		  keywords: "transactions, historique, hooyia, e-book, paiements",
		  ogTitle: "Historique des transactions | HooYia",
		  ogDescription:
			"Consultez le détail de chacune de vos transactions sur HooYia e-book store.",
		  ogImage: "https://www.e-hooyia.com/assets/transactions-og.jpg",
		  twitterTitle: "Historique des transactions | HooYia",
		  twitterDescription:
			"Visualisez l'ensemble de vos opérations financières sur HooYia.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/transactions`,
		  noindex: false,
		};
	  };
	  
	  // 9. Seller Stats (Statistics)
	  const getSellerStatsSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Statistiques de vente | HooYia e-book store",
		  description:
			"Analysez vos performances de vente et suivez les statistiques de vos e-books sur HooYia.",
		  keywords: "statistiques, ventes, hooyia, e-book, performance",
		  ogTitle: "Statistiques de vente | HooYia",
		  ogDescription:
			"Visualisez vos indicateurs clés et optimisez vos stratégies sur HooYia.",
		  ogImage: "https://www.e-hooyia.com/assets/seller-stats-og.jpg",
		  twitterTitle: "Statistiques de vente | HooYia",
		  twitterDescription:
			"Suivez en temps réel les performances de vos e-books sur HooYia e-book store.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/statistics`,
		  noindex: false,
		};
	  };
	  
	  // 10. User Dashboard (pour la route : /{first_name}/dashboard)
	  const getUserDashboardSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Mon Dashboard | HooYia e-book store",
		  description:
			"Visualisez vos activités, e-books et ventes dans votre dashboard personnalisé sur HooYia.",
		  keywords: "dashboard, hooyia, e-book, gestion, vendeur",
		  ogTitle: "Mon Dashboard | HooYia",
		  ogDescription:
			"Accédez à un aperçu complet de vos activités sur HooYia e-book store.",
		  ogImage: "https://www.e-hooyia.com/assets/user-dashboard-og.jpg",
		  twitterTitle: "Dashboard | HooYia",
		  twitterDescription:
			"Gérez vos e-books et ventes grâce à votre espace personnalisé sur HooYia.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/dashboard`,
		  noindex: false,
		};
	  };
	  
	  // 11. Discount
	  const getDiscountSeoData = (props) => {
		const firstName = props.user?.user?.first_name || "Utilisateur";
		return {
		  title: "Offres et réductions | HooYia e-book store",
		  description:
			"Profitez des meilleures offres et réductions sur vos e-books préférés sur HooYia.",
		  keywords: "réductions, offres, discount, hooyia, e-book",
		  ogTitle: "Offres et réductions | HooYia",
		  ogDescription:
			"Découvrez nos promotions exclusives sur HooYia e-book store.",
		  ogImage: "https://www.e-hooyia.com/assets/discount-og.jpg",
		  twitterTitle: "Offres & Réductions | HooYia",
		  twitterDescription:
			"Ne manquez pas les meilleures offres sur vos e-books sur HooYia.",
		  canonicalUrl: `https://www.e-hooyia.com/${firstName}/discount`,
		  noindex: false,
		};
	  };
	  
	  // --- Création des composants enveloppés avec withSEO ---
	  const UserProfileWithSEO = withSEO(UserProfile, getUserProfileSeoData);
	  const ChatWithSEO = withSEO(Chat, getChatSeoData);
	  const SellerBookCreateWithSEO = withSEO(BookCreate, getSellerBookCreateSeoData);
	  const SettingsWithSEO = withSEO(Settings, getSettingsSeoData);
	  const SellerProfileWithSEO = withSEO(SellerProfile, getSellerProfileSeoData);
	  const SellerProfileEditWithSEO = withSEO(SellerProfileEdit, getSellerProfileEditSeoData);
	  const EarningWithSEO = withSEO(Earning, getEarningSeoData);
	  const TransactionHistoryWithSEO = withSEO(TransactionHistory, getTransactionSeoData);
	  const SellerStatsWithSEO = withSEO(SelllerStats, getSellerStatsSeoData);
	  const UserDashboardWithSEO = withSEO(Dashboard, getUserDashboardSeoData);
	  const DiscountWithSEO = withSEO(Discount, getDiscountSeoData);
	  

	const { limit, offset, setTotalItems } = usePagination();
	const [logoutModal, setLogoutModal] = useState(false);
	const [books, setBooks] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const logoutModalController = () => {
		setLogoutModal(!logoutModal)
	}

	useEffect(() => {
		setIsLoading(true)
		getBooks()
		setIsLoading(false)
	}, [offset]);

	// get books and set state
	// const getBooks = async () => {
	// 	const response = await getRequest("/seller/ebook/owner-ebook/")
	// 	if(response.statusText === "OK"){
	// 		setBooks(response.data.results)
	// 	} 
	// }

	const getBooks = async () => {
		const response = await getRequest(`/seller/ebook/owner-ebook/?limit=${limit}&offset=${offset}`)
		setBooks(response?.data?.results)
		setTotalItems(response?.data?.count || 0)
	}


	return (
<>
      <NavSeller user={user} handleRemoveAuthModal={logoutModalController} />

    {
	  	logoutModal && <Logout handleRemoveAuthModal={logoutModalController} />
	}
	
    <div className="seller_pages_with_nav">
        <Routes>
          {/* Routes dynamiques avec informations utilisateur */}
          <Route
            path={`/${user?.user?.first_name}`}
            element={<UserProfileWithSEO user={user} />}
          />
          <Route
            path={`/${user?.user?.first_name}/inbox`}
            element={<ChatWithSEO user={user} />}
          />
          <Route
            path={`/book/create-book`}
            element={<BookCreate user={user} />}
          />
          <Route
            path={`/book/:id`}
            element={<BookDetails books={books} user={user} />}
          />
          <Route
            path={`/ebooks/:id`}
            element={<BookDetails books={books} user={user} />}
          /> 
          <Route
            path={`/${user?.user?.first_name}/book`}
            element={<SellerBookCreateWithSEO user={user} />}
          />
          <Route
            path={`/${user?.user?.first_name}/settings`}
            element={<SettingsWithSEO user={user} />}
          />
          <Route
            path={`/${user?.user?.first_name}/profile`}
            element={<SellerProfileWithSEO user={user} />}
          />
          <Route
            path={`/${user?.user?.first_name}/profile?`}
            element={<SellerProfileEditWithSEO user={user} />}
          />
          <Route
            path={`/${user?.user?.first_name}/earnings`}
            element={<EarningWithSEO user={user} />}
          />
          <Route
            path={`/${user?.user?.first_name}/transactions`}
            element={<TransactionHistoryWithSEO user={user} />}
          />

          {/* Autres routes non dynamiques */}
          <Route
            path="/"
            element={<Dashboard user={user} books={books} isLoading={isLoading} />}
          />
          {/* <Route path="/me/:id" element={<StoreProfile  />} /> */}

          {/* Route avec lazy loading enveloppée via withSEO */}
          <Route
            path={`/${user?.user?.first_name}/statistics`}
            element={
              <Suspense fallback={<lazyLoader />}>
                <SellerStatsWithSEO user={user} />
              </Suspense>
            }
          />

          {/* <Route
            path="/me/*"
            element={<Dashboard user={user} books={books} isLoading={isLoading} />}
          /> */}
          <Route path="/switch-to-buying" element={<InfoSwitchBuying />} />
          <Route
            path={`/${user?.user?.first_name}/dashboard`}
            element={<UserDashboardWithSEO user={user} books={books} />}
          />
          <Route
            path={`/${user?.user?.first_name}/discount?`}
            element={<DiscountWithSEO books={books} user={user} />}
          />
          <Route path="/terms-and-conditions" element={<SellerPrivacy />} />

          {/* CONTACT ADMIN AND FAQ */}
          <Route path="/frequently-asked-question/*" element={<Faq />} />
          <Route path="/help/contact-support" element={<Contact />} />
          <Route path="/help/message-submitted" element={<RequestSubmitted />} />
        </Routes>
      </div>
    </>
	)
}

export default AppSeller
