import React, { useEffect, useState } from 'react';
import './Ebook.css';
import ProductGrid from '../../Products/ProductGrid';
import { getRequest } from '../../../Resquest';
import { useTranslation } from 'react-i18next';
import { InstantSearch, Hits, SearchBox } from 'react-instantsearch';
import { searchClient } from '../../../searchClient';
import Pagination from '../../Addons/Pagination/pagination';
import { ReactComponent as EbooksSvg } from '../../../Assets/img/ebook_svg.svg';
import LoadingPage from '../../SELLER/Dashboard/Components/LoadingPage';
import { useSearchParams } from 'react-router-dom';
import { usePagination } from "../../../Context/PaginationContext";
import { setMetaDescription } from '../../..';
import { useNavigate } from 'react-router-dom';
import NoBookFound from '../../../Assets/img/no_books_found_image.png';

import { updateMetaTags } from '../../../lib/withSEO';

const Ebooks = () => {
  const [ebooks, setEbooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [bookCount, setBookCount] = useState(0);
  const { limit, offset, setTotalItems } = usePagination();
  const [searchParams] = useSearchParams(); // Use useSearchParams to read query parameters
  const category = searchParams.get('category');

  const seoEbooks = {
    title: "E-books | HooYia e-book store",
    description:
      "Parcourez l’ensemble de nos e-books et trouvez votre prochaine lecture parmi une sélection variée sur HooYia.",
    keywords: "ebooks, hooyia, e-book, lecture, achat",
    ogTitle: "E-books | HooYia",
    ogDescription:
      "Découvrez une large sélection d’e-books sur HooYia e-book store.",
    ogImage: "https://www.e-hooyia.com/assets/ebooks-og.jpg",
    twitterTitle: "E-books | HooYia",
    twitterDescription:
      "Votre destination pour découvrir et acheter des e-books sur HooYia.",
    canonicalUrl: "https://www.e-hooyia.com/ebooks",
    noindex: false,
  };

  useEffect(() => {
    getBookWithCat();
  }, [category, offset]);


  useEffect(() => {
    updateMetaTags(seoEbooks);
  }, []);

  const getBookWithCat = async () => {
    setIsLoading(true);
    let response;

    try {
      // Use the 'category' query parameter to filter ebooks
      response = category
        ? await getRequest(`/public/ebook/display/?categories=${category}&limit=${limit}&offset=${offset}`)
        : await getRequest(`/public/ebook/display/?limit=${limit}&offset=${offset}`);

      if (response.statusText === "OK") {
        saveData(response.data);
      }
    } catch (error) {
      console.error("Error fetching ebooks:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveData = (ebook_data) => {
    setEbooks(ebook_data?.results || []);
    setBookCount(ebook_data?.count || 0);
    setTotalItems(ebook_data?.count || 0);
  };

  const { t } = useTranslation();

  const queryHook = (query, search) => {
    search(query);
    query.length > 0 ? setIsSearching(true) : setIsSearching(false);
  };

  useEffect(() => {
    const bookDescription = t("randoms.ebook_description");
    const ogBookDescription = t("randoms.ebook_description");

    setMetaDescription(bookDescription, ogBookDescription);

    return () => {
      setMetaDescription("", "");
    };
  }, []);

  return (
    <InstantSearch searchClient={searchClient} indexName="hooyia_index_ebook" insights={true}>
      <div className="ebook_n">
        <div className="container">
        <h1 className='title_browse'> Don't waste time, browse across the lastest literary, scientific or professional trendings !</h1>
          <SearchBox
            queryHook={queryHook}
            className="search_n_user"
            placeholder="search..."
          />
          <div className="ebook_grid_n">
            <div className={`ebook_list_n ${isSearching ? "showaside" : ""} `}>
              {isSearching ? (
                  <div className="search_result_non_user">
                    <Hits hitComponent={Hit}/>
                  </div>
              ) : (
                  <div className="no_search_aside">
                    <div className="title">{t("non_user_ebooks.title")}</div>
                    <p>{t("non_user_ebooks.text")}</p>
                    <div className="svg_login">
                      <EbooksSvg/>
                    </div>
                    <p>{t("non_user_ebooks.more_text")}</p>
                  </div>
              )}
            </div>
            <div className="ebook_subgrid_n">
              {isLoading ? (
                  <LoadingPage/>
              ) : ebooks.length === 0 ? (
                  <div className='no_books_cat_section'>
                    <div className="message">
                      <p className='no_books_cat_title'>{t('no_ebook_found.title')}</p>
                      <p className='no_books_cat_text'>{t('no_ebook_found.desc')}</p>
                    </div>
                    <img src={NoBookFound} alt="" className='illustrative_cat_image'/>
                  </div>
              ) : (
                  <ProductGrid products={ebooks}/>
              )}
            </div>

          </div>
          <div className="pagination_btn_wrapper">
            <Pagination/>
          </div>
        </div>
      </div>
    </InstantSearch>
  );
};

export default Ebooks;

const Hit = ({hit}) => {
  const navigate = useNavigate();
  return (
      <div onClick={() => navigate(`/ebooks/${hit?.objectID}`)}>
        <div className='hit_grid'>
          <div className='hit_item_image'>
            <img className='img' src={'https://e-hooyia.s3.amazonaws.com/' + hit?.cover} alt={hit?.name} />
        </div>
        <div className='hit_desc'>
          <p className='hit_title'>{hit?.name}</p>
          <p className='hit_auth'>By: {hit?.author}</p>
        </div>
      </div>
    </div>
  );
};