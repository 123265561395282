import React, { useState, useRef, useEffect } from 'react';
import useLocalStorage from '../../lib/UseLocalStorage';
import { postRequest } from '../../../Resquest';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateMetaTags } from '../../../lib/withSEO';
import {ReactComponent as ThreeDots} from '../../../Assets/Loaders/ThreeDots.svg'
import { toast } from 'sonner';


const PhoneVerify = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
    const [countdown, setCountdown] = useLocalStorage('countdown', 600); 
    const [ isLoading, setIsLoading ] = useState(false);

    const location = useLocation();
    const user = location.state && location.state.data?.id;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const { t } = useTranslation();

    const seoPhoneVerify = {
        title: "Vérification du numéro | HooYia e-book store",
        description:
          "Confirmez votre numéro de téléphone pour sécuriser et activer votre compte sur HooYia.",
        keywords: "vérification numéro, téléphone, hooyia, compte, sécurité",
        ogTitle: "Vérification du numéro | HooYia",
        ogDescription:
          "Validez votre numéro de téléphone pour renforcer la sécurité de votre compte sur HooYia.",
        ogImage: "https://www.e-hooyia.com/assets/phone-verify-og.jpg",
        twitterTitle: "Vérification du numéro | HooYia",
        twitterDescription:
          "Confirmez votre numéro de téléphone pour activer votre compte sur HooYia.",
        canonicalUrl: "https://www.e-hooyia.com/verify-nummber",
        noindex: false,
      };
      useEffect(() => {
        updateMetaTags(seoPhoneVerify)
    }, []);

    const handleSubmit = async() => {

        setIsLoading(true)

        const data = {
            "user_id": user,
            "code": verificationCode
        }
        const res = await postRequest('/auth/verify-code/', data)
        if(res?.status === 200) {
            window.location.href = '/login?phone_verified=true'
            setIsLoading(false)
            setTimeout(() => { 
                toast.success(t('randoms.whatsapp_account_created'), {
                    position: 'top-center',
                    duration: 5000
                })
            }, 5000);
        }
        else{
            setIsLoading(false);
            alert(t("signupPage.invalidVerificationCode"))
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (verificationCode.length === 5) {
            handleSubmit();
        }
    }, [verificationCode]);


    useEffect(() => {
        // Check if there's a stored timestamp in localStorage
        const storedTimestamp = localStorage.getItem('countdownTimestamp');
        const currentTime = Math.floor(Date.now() / 1000);

        if (storedTimestamp) {
            const elapsedTime = currentTime - parseInt(storedTimestamp, 10);
            const remainingTime = Math.max(0, countdown - elapsedTime);
            setCountdown(remainingTime);
        }

        // Update localStorage with the current timestamp
        localStorage.setItem('countdownTimestamp', currentTime.toString());
    }, [countdown]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
        }, 1000);

        return () => clearInterval(timer);
    }, [setCountdown]);



    const requestNewCode = () => {
        setCountdown(600);
    }

    const handlePaste = (e) => {
        e.preventDefault();
        // if(!isMobile){
            const pastedValue = e.clipboardData.getData('text');
            const formattedValue = pastedValue.slice(0, 5).split('');
        
            formattedValue.forEach((char, index) => {
                if (inputRefs[index] && char.match(/^[0-9a-zA-Z]$/)) {
                    setVerificationCode((prevCode) => {
                        const updatedCode = prevCode.slice(0, index) + char + prevCode.slice(index + 1);
                        return updatedCode.length <= 5 ? updatedCode : prevCode;
                    });
        
                    if (index === 4) {
                        inputRefs[index].current.setSelectionRange(5, 5);
                    }
                }
            });
        // }/

    };

    const handleInput = (e, index) => {
        const pressedKey = e.key;
        // if (isMobile) {
            if (/^[0-9a-zA-Z]$/.test(pressedKey)) {
                setVerificationCode((prevCode) => {
                    const updatedCode = prevCode.slice(0, index) + pressedKey + prevCode.slice(index + 1);
                    return updatedCode.length <= 5 ? updatedCode : prevCode;
                });
    
                if (index < 4) {
                    inputRefs[index + 1].current.focus();
                }
            } else if (pressedKey === 'Backspace' && index > 0) {
                setVerificationCode((prevCode) => prevCode.slice(0, index - 1) + prevCode.slice(index));
                inputRefs[index - 1].current.focus();
            }
        // }
    };


    const handleKeyDown = (e, index) => {
        const pressedKey = e.key;

        // if(!isMobile){
            if (/^[0-9a-zA-Z]$/.test(pressedKey)) {
                setVerificationCode((prevCode) => {
                    const updatedCode = prevCode.slice(0, index) + pressedKey + prevCode.slice(index + 1);
                    return updatedCode.length <= 5 ? updatedCode : prevCode;
                });
        
                if (index < 4) {
                    inputRefs[index + 1].current.focus();
                }
            } else if (pressedKey === 'Backspace' && index > 0) {
                setVerificationCode((prevCode) => prevCode.slice(0, index - 1) + prevCode.slice(index));
                inputRefs[index - 1].current.focus();
            }
        // }
    };

    const saveString = (letter) => {
        setVerificationCode(letter)
    }
    
    
    return (
        <div className='container'>
            <div className='verify_number_container'>
                <p className='verify_number_title'>{ t("signupPage.verfiy_code_title") }</p>
                <div className='verification_number_fields_mobile'>
                    <input type='text' className='verification_input_number' placeholder='XXXXX' value={verificationCode} onChange={e=> saveString(e.target.value)} />
                </div>
                <div className='verification_number_fields'>
                    {Array.from({ length: 5 }, (_, index) => (
                        <div key={index} className='verification_letter'>
                            <input
                                type='text'
                                value={verificationCode[index] || ''}
                                className='verification_input_number'
                                ref={inputRefs[index]}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                            />
                        </div>
                    ))}
                </div>
                <div className='verify_number_btn' onClick={handleSubmit}>
                    { isLoading ? <ThreeDots/> :  t("signupPage.verify_code_btn") }
                </div>
                <div className='verification_number_description'>
                    <p className='code_not_sent'>
                        { t("signupPage.verify_code_no_code") } <span onClick={requestNewCode} className='link'>{ t("signupPage.verify_code_resend_btn") }</span>
                    </p>
                    <p>{ t("signupPage.verify_code_text_one") }</p>
                    <p className='ver_code_timer'>{ t("signupPage.verify_code_text_expire") } {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')} minutes.</p>
                </div>
            </div>
        </div>
    );
};

export default PhoneVerify;
