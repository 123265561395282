import React, { useContext, useEffect, useState } from 'react';
import './Cart.css';
import { BsShieldCheck } from 'react-icons/bs';
import CartItem from './CartItem';
import { useNavigate } from 'react-router-dom';
import Popup from '../Addons/Popup';
import { WishListContext } from '../../Context/WishListContext';
import { useTranslation } from 'react-i18next';
import { updateMetaTags } from '../../lib/withSEO'

const WishList = () => {
	const { t } = useTranslation();
	const [wishList, setWishList] = useContext(WishListContext);
	const [total, setTotal] = useState(0);
	const [wishListAdded, setWishListAdded] = useState(false);
	const [wishListRemoved, setWishListRemoved] = useState(false);
	const navigate = useNavigate();
	
	const seoWishList = {
		title: "Liste de souhaits | HooYia e-book store",
		description:
		  "Enregistrez vos e-books préférés dans votre liste de souhaits et retrouvez-les facilement pour un achat ultérieur.",
		keywords: "liste de souhaits, hooyia, e-book, favoris, achat",
		ogTitle: "Liste de souhaits | HooYia",
		ogDescription:
		  "Accédez à vos e-books préférés sauvegardés pour un achat futur sur HooYia.",
		ogImage: "https://www.e-hooyia.com/assets/wishlist-og.jpg",
		twitterTitle: "Liste de souhaits | HooYia",
		twitterDescription:
		  "Retrouvez vos e-books favoris dans votre liste de souhaits sur HooYia.",
		canonicalUrl: "https://www.e-hooyia.com/wishList",
		noindex: false,
	  };
	
	  useEffect(() => {
		updateMetaTags(seoWishList);
	  }, []);

	const removeProduct = (id) => {
		setWishList(wishList.filter(item => item.slug !== id));
	};

	const removePopup = () => {
		setWishListAdded(false);
		setWishListRemoved(false);
	};

	const animateWishListRemoved = () => {
		setWishListRemoved(true);
		setTimeout(() => {
			setWishListRemoved(false);
		}, 3000);
	};

	const animateWishListAdded = () => {
		setWishListAdded(true);
		setTimeout(() => {
			setWishListAdded(false);
		}, 3000);
	};

	const wishListChanged = (item) => {
		removeProduct(item.slug);
		animateWishListRemoved();
	};

	useEffect(() => {
		getTotal();
	}, [wishList]);

	const getTotal = () => {
		let total = 0;
		wishList.map(item => total += Number(item.price));
		setTotal(total);
	};

	return (
		<div className='container'>
			<div className='cart'>
				<div className='cart_list_section'>
					<div className='cartSection'>
						<div className='title'>{t("wishList.title")} ({wishList.length})</div>
						<div className='desc'>
							<span className='icon'><input type='radio' /></span>
							<span>{t("wishList.select")}</span>
						</div>
					</div>
					<div className='cartItemsSection'>
						<div className='desc lineCart'>
							<span className='icon'><input type='radio' /></span>
							<span className='title'>{t("wishList.subTitle")}</span>
						</div>
						{wishList.map(product => (
							<CartItem
								key={product.slug}
								product={product}
								wishListChanged={wishListChanged}
								animateWishListAdded={animateWishListAdded}
							/>
						))}
					</div>
				</div>
				<div className='cart_summary'>
					<div className='summary'>
						<div className='title'>Summary</div>
						<div className='summary_det'>
							<p>subtotal</p>
							<p>XAF{total}</p>
						</div>
						<div className='summary_det'>
							<p>shipping fee</p>
							<p>XAF0.0</p>
						</div>
						<div className='summary_det'>
							<p>total</p>
							<p>XAF{total}</p>
						</div>
						<div onClick={() => navigate('/checkout')} draggable className='checkout_button'>checkout ({wishList.length})</div>
					</div>
					<div className='summary payment_methods'>
						<div className='title'>{t("cart.pay")}</div>
						<div className='payIcons'>
							<span className='icon'></span>
							<span className='icon'></span>
							<span className='icon'></span>
						</div>
						<div className='title'>{t("cart.bp")}</div>
						<p>
							<span><BsShieldCheck /> </span>
							<span className='protect'>{t("cart.bp_desc")}</span>
						</p>
					</div>
				</div>
			</div>
			<Popup handleRemoveModal={removePopup} showPopup={wishListAdded} text={"Added to wish list"} />
			<Popup handleRemoveModal={removePopup} showPopup={wishListRemoved} text={"removed from wish list"} />
		</div>
	);
};

export default WishList;