import React, { useEffect } from 'react'
import AboutUsSection from './AboutUsSection'
import '../../NON-USER/About/newAbout.css'
import { tabTitle } from '../../../index'
import { useTranslation } from 'react-i18next'
import WhatWeDoSection from './WhatWeDoSection'
import SplashSection from './SplashSection'
import LocationSection from './location/LocationSection'
import { updateMetaTags } from '../../../lib/withSEO'

const AboutPage = () => {

  const seoAboutPage = {
    title: "À propos de nous | HooYia e-book store",
    description:
      "Découvrez l'histoire et la mission de HooYia, la plateforme dédiée aux e-books et à la lecture.",
    keywords: "à propos, hooyia, e-book, histoire, mission",
    ogTitle: "À propos de nous | HooYia",
    ogDescription:
      "Apprenez-en plus sur HooYia et notre engagement pour la promotion de la lecture numérique.",
    ogImage: "https://www.e-hooyia.com/assets/about-us-og.jpg",
    twitterTitle: "À propos de nous | HooYia",
    twitterDescription:
      "Découvrez qui se cache derrière HooYia, la boutique en ligne d'e-books.",
    canonicalUrl: "https://www.e-hooyia.com/about-us",
    noindex: false,
  };

    useEffect(() => {
      updateMetaTags(seoAboutPage)
    }, [])

  const { t } = useTranslation();

  return (
    <div className='about_page'>
      <div className='landing_explore about_expolore'>
          <div className='about_container'>
            <h1>{ t('about_page.title') } <span>HooYia</span></h1><br />
            <p>{ t('about_page.desc') }<a href="https://hooyia.net/en/" className="s_more">See more</a></p>
          </div>
      </div>
      <WhatWeDoSection />
      {/* <SplashSection  /> */}
      <LocationSection />
      <AboutUsSection />
    </div>
  );
}

export default AboutPage;
