import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import './Dashboard.css'
import DefaultProfilePic from '../../../Assets/img/personPlaceholder.jpg'
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {RiSearch2Line} from 'react-icons/ri'
import ListBooks, {CopyAlert} from './Components/ListBooks'
import NoBooks from './Components/NoBooks'
import facebook from '../../../Assets/img/facebook.png';
import linkedin from '../../../Assets/img/linkedin.png';
import twitter from '../../../Assets/img/twitter.png';
import ChatDetails from '../../mesages/ChatDetails'
import { BsArrowLeft, BsArrowRight} from 'react-icons/bs'
import {SellerReloadContext} from '../../../Context/SellerReload'
import LoadingPage from './Components/LoadingPage'
import {AnimatePresence} from 'framer-motion'
import {FaLink} from 'react-icons/fa'
import {BsBook} from 'react-icons/bs'
import {FiShoppingCart} from 'react-icons/fi'
import {FiEdit} from 'react-icons/fi'
import {CartContext} from '../../../Context/CartContext'
import {BsStarFill} from 'react-icons/bs'
import {AWSbaseURL} from '../../../Resquest';
import {toast} from 'sonner';
import Pagination from "../../Addons/Pagination/pagination";
import { withSEO } from '../../../lib/withSEO'

const Dashboard = ({user, books, isLoading}) => {
    const [searchString, setSearchString] = useState('');
    const [sellerBooks, setSellerBooks] = useState(books);
    const [booksCopy, setBooksCopy] = useState(books);
    const [showDashBoardChat, setShowDashBoardChat] = useState(false);
    const [conversationWith, setConversationWith] = useState('');
    const [sellerReload, setSellerReload] = useContext(SellerReloadContext);
    const [copiedStore, setCopiedStore] = useState(false);
    const [baseMe, setBaseMe] = useState(user?.user?.seller_ebook_shop_url);
    const [cartList, setCartList] = useContext(CartContext);
    const location = useLocation()
    const navigate = useNavigate()
    const [queryParameters] = useSearchParams()
    const book_has_create = queryParameters.get("book-created")
    const is_shared_route = window.location.pathname.includes('/me/');

    useEffect(() => {
        setSellerBooks(books);
        setBooksCopy(books);
        if (sellerReload) {
            setSellerReload(false)
            window.location.reload()
        }
    }, [user, books])

    useEffect(() => {
        if (is_shared_route) {
            const me_param = window.location.pathname.split('/me/')[1]
            const my_username = baseMe.split('me/')[1]
            if (me_param === my_username) {
                return
            }
            navigate('/switch-to-buying', {state: {path: '/me' + me_param}})
        }
        if (book_has_create) {
            window.location.href = '/';
        }
    }, []);


    if (location?.state?.logout) {
        window.location.reload()
    }

    const filterMyBooks = (text) => {
        setSearchString(text)
        const b = booksCopy.filter(book => (book?.name).includes(text) || (book?.description).includes(text))
        setSellerBooks(b)
    }

	const copyLink = () => {
		const link =  user.user.seller_ebook_shop_url
		navigator.clipboard.writeText(link)
		setCopiedStore(true)
		setTimeout(() => {
			setCopiedStore(false)
		}, 3000);
	}
	const textURL = "visit my e-hooyia shop now"

    const {t} = useTranslation()
    return (
        <div className='seller_dashboard'>
            <div className='container'>
                <div className='seller_dashboard_grid'>
                    <section className='seller_dashboard_section dashboard_seller_leftmain'>
                        <div className='seller_profile_card'>
                            <div className="top_bloc">
                                <div className='profilePic'>
                                    {
                                        user?.user?.profile?.pp
                                            ?
                                            <img src={AWSbaseURL + (user?.user?.profile?.pp)} alt='profile'
                                                 className='profile-img'
                                                 style={{color: 'red', backgroundSize: 'cover'}}/>
                                            :
                                            <img src={DefaultProfilePic} alt='profile-picture'/>

                                    }
                                </div>
                                <div className='title ids'>
                                    <span className='name'>{user?.user.first_name} {user?.user.last_name}</span>
                                    <span className='email'>{user?.user.email}</span>
                                </div>
                            </div>
                            <div className='statistics'>
                                <div className="parameter">
                                    <div className='text_and_icons' onClick={() => navigate('/my-books')}>
                                        <div className='iconProp' aria-hidden="true">
                                            <BsBook className='iconItem iconBook'/>
                                        </div>
                                        <div className='property'>
                                            {t("dashboard.books")}{(books?.length > 1) && 's '}
                                        </div>
                                    </div>
                                    <p className='parameter_value'>{books?.length}</p>
                                </div>
                                <div className="parameter">
                                    <div className='text_and_icons'><span className='iconProp'><FiShoppingCart
                                        className='iconItem iconCart'/></span><span
                                        className="property">{t('dashboard.cart')}</span></div>
                                    <p className="parameter_value">{cartList ? cartList?.length : 0}</p>
                                </div>
                                <div className="parameter">
                                    <div className='text_and_icons'><span className='iconProp'><BsStarFill
                                        className='star_icon iconItem'/></span><span
                                        className="property">{t('dashboard.rate')}</span></div>
                                    <p className="parameter_value">{cartList ? cartList?.length : 0}</p>
                                </div>
                            </div>
                            <div className="edit_btn_dashboard">
                                <Link to={`/${user?.user?.first_name}/profile?edit=true`} className='edit_profile'>
                                    <div className="edit_profile"><span
                                        className='iconProp'><FiEdit/></span>{t("dashboard.edit")}</div>
                                </Link>
                            </div>
                        </div>

						<div className='seller_chat_minor'>
							<div className='share_shop bold'>{ t("share.title") }</div>
							<div className='share_links'>
								<ul>
									<li className="d_flex_link">
									<a href={ `https://www.facebook.com/sharer/sharer.php?u=${user.user.seller_ebook_shop_url}`} target="_blank" rel="noreferrer">{ t("share.fb") }</a>
										<img src={facebook} width={20} height={20}/>
									</li>
									<li className="d_flex_link">
										<a href={`https://www.linkedin.com/sharing/share-offsite/?url=${user.user.seller_ebook_shop_url}`} target="_blank" rel="noreferrer">{ t("share.linkedin") }</a>
										<img src={linkedin} width={22} height={22}/>
									</li>
									<li className="d_flex_link">
										<a href={ `https://twitter.com/intent/tweet?url=${user.user.seller_ebook_shop_url}&text=${textURL}`}
										   target="_blank" rel="noreferrer">{t("share.tweeter")}</a>
										<img src={twitter} width={20} height={20}/>
									</li>
									<li onClick={copyLink} className='copy_store'>
										<span>{ t("share.copy") }</span>
										<span><FaLink /></span>
									</li>
								</ul>
							</div>
						</div>
						{/* <div className='seller_chat_minor'>
							chat
							<div className='chat_messages'>
								<Chatters handleConversation = {conversation} chatList={chatList} />
							</div>
						</div> */}
                    </section>
                    <section className='seller_dashboard_section dashboad_seller_main'>
                        <div className='seller_dashboard_main'>
                            {
                                showDashBoardChat
                                    ?
                                    (
                                        <div className='dashboard_chat_fixed'>
                                            <div className='dashboard_chat_title'>
                                                <span onClick={() => setShowDashBoardChat(false)}><span
                                                    className="return_link_icon_sd"><BsArrowLeft/></span>Return</span>
                                                <h4>Conversation with Pablo</h4>
                                                <Link to={`/${user?.user?.first_name}/dashboard`}>Inbox<span
                                                    className="return_link_icon_sd_right"><BsArrowRight/></span></Link>
                                            </div>
                                            <ChatDetails chatId={conversationWith}/>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='search search_seller'>
                                                <input type='text' onChange={e => filterMyBooks(e.target.value)}
                                                       value={searchString} placeholder='search...'/>
                                                <span className='searchIcon'>
											<RiSearch2Line/>
										</span>
                                            </div>
                                            <div className='bookList'>
                                                {
                                                    isLoading ?
                                                        <LoadingPage/>
                                                        :
                                                        sellerBooks?.length < 1
                                                            ?
                                                            <NoBooks/>
                                                            :
                                                            <ListBooks books={sellerBooks} user={user}/>
                                                }
                                            </div>
                                        </>

                                    )
                            }

                        </div>
                        {/*	 pagination lies here  */}
                        <Pagination/>
                    </section>
                    {/* <section className='seller_dashboard_section dashboard_seller_notification' id='seller_notification'>
						<p className='title'>{ t("dashboard.notification") }</p>
						<ul>
							<li draggable className='notification_item'>
								<p className='notification_title'>Message from paul</p>
								<p className='notification_desc'>
									<span className='desc_text'>This is a detailed description of the notification</span>
									<span className='notification_time'>12:40</span>
								</p>
							</li>
							
						</ul>
					</section> */}
                </div>
            </div>
            <AnimatePresence
                initial={false}
                mode='wait'
            >
                {copiedStore && (
                    <CopyAlert/>
                )}
            </AnimatePresence>
        </div>
    )
}
export const seoDashboard = {
	title: "Accueil | HooYia e-book store",
	description:
	  "Bienvenue dans votre espace marchand sur HooYia, la plateforme incontournable pour acheter et vendre des e-books. Faites connaître à travers cet espace, votre talent au monde entier.",
	keywords: "accueil, hooyia, e-book, boutique, nouveautés",
	ogTitle: "Accueil | HooYia e-book store",
	ogDescription:
	  "Gérez vos activités  sur HooYia grâce à votre espace personnalisé.",
	ogImage: "https://www.e-hooyia.com/assets/dashboard-og.jpg",
	twitterTitle: "Accueil | HooYia",
	twitterDescription:
	  "Votre destination pour découvrir et acheter des e-books sur HooYia.",
	canonicalUrl: "https://www.e-hooyia.com/",
	noindex: false,
  };
  
export default Dashboard
