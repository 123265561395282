import React, { useEffect, useRef } from 'react'

import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { updateMetaTags } from '../../../lib/withSEO';
const PasswordResetLinkExpired = () => {

    const seoPasswordResetLinkExpired = {
        title: "Lien expiré | HooYia e-book store",
        description:
          "Le lien de réinitialisation de votre mot de passe a expiré. Veuillez en demander un nouveau.",
        keywords: "lien expiré, réinitialisation, hooyia, mot de passe",
        ogTitle: "Lien expiré | HooYia",
        ogDescription:
          "Votre lien de réinitialisation n'est plus valide. Veuillez demander un nouveau lien pour réinitialiser votre mot de passe.",
        ogImage: "https://www.e-hooyia.com/assets/password-reset-link-expired-og.jpg",
        twitterTitle: "Lien expiré | HooYia",
        twitterDescription:
          "Le lien pour réinitialiser votre mot de passe a expiré. Demandez-en un nouveau sur HooYia.",
        canonicalUrl: "https://www.e-hooyia.com/password-reset-link-expired",
        noindex: false,
      };

    useEffect(() => {
        updateMetaTags(seoPasswordResetLinkExpired);
    }, []);

    return (
        <div className='email_verify'>
            <div className='container'>
                <div className='email_verify_loader'></div>
                <p className='verify_email_text'>Password reset link is expired. Go to the password reset form and re-enter your email</p>
                <p className='continue_browsing'>
                    <Link to='/forgot-password'><BsArrowLeft />Go to form</Link>
                </p>
            </div>
        </div>
    )
}

export default PasswordResetLinkExpired
