import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { updateMetaTags } from '../../lib/withSEO';

const NotSellerDashboard = () => {

  const seoNotSellerDashboard = {
    title: "Dashboard | HooYia e-book store",
    description:
      "Accédez à votre espace de gestion et visualisez vos activités sur HooYia.",
    keywords: "dashboard, hooyia, e-book, gestion, compte",
    ogTitle: "Dashboard | HooYia",
    ogDescription:
      "Visualisez vos activités et gérez votre compte sur HooYia e-book store.",
    ogImage: "https://www.e-hooyia.com/assets/dashboard-og.jpg",
    twitterTitle: "Dashboard | HooYia",
    twitterDescription:
      "Votre espace de gestion sur HooYia e-book store.",
    canonicalUrl: "https://www.e-hooyia.com/:id/dashboard",
    noindex: false,
  };
  useEffect(() => {
    updateMetaTags(seoNotSellerDashboard);
  }, [])

  return (
    <div>
        
      you are not a seller. <Link to='/seller-onboarding/seller-details'>click here </Link> to become a seller 
    </div>
  )
}

export default NotSellerDashboard
