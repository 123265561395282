import React, { useContext, useLayoutEffect, useState, useEffect } from 'react'
import './Profile.css'
import { getRequest } from '../../Resquest'
import ProductGrid from '../Products/ProductGrid'
import { useTranslation } from 'react-i18next'
import { tabTitle } from '../..'
import { FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { UserContext } from '../../Context/AuthContext'
import { IsSellercontext } from '../../Context/IsSellerContext'
import { SellerReloadContext } from '../../Context/SellerReload'
import { useNavigate } from 'react-router-dom'
import { AWSbaseURL } from '../../Resquest'
const UserProfile = ({ user, username }) => {
        const [baseMe, setBaseMe] = useState(user?.user?.seller_ebook_shop_url)
        const [userData, setUserData] = useState({})
        const [isloading, setIsloading] = useState(false)
        const [ebooks, setEbooks] = useState([])
        const [store_owner, setStore_owner] = useState(true)
        const [isSellerAccount, setIsSellerAccount] = useContext(IsSellercontext);
        const [sellerReload, setSellerReload] = useContext(SellerReloadContext)
        const navigate = useNavigate()
        useLayoutEffect(() => {
                setIsloading(true)
                fetchPublishedBooks()
        }, [])
        const fetchPublishedBooks = async() => {
                const base = baseMe.split('me/')[1]
                let endpoint = "/ebook-store/"+ base;
                request(endpoint)
        }
        const request = async(endpoint) => {
                const res = await getRequest(endpoint)
                res.status === 200 && saveResponse(res.data);
        }
        const saveResponse = (data) => {
                setUserData(data)
                setEbooks(data?.ebooks)
                setIsloading(false)
        }
        tabTitle(`${user?.user?.first_name} ${user?.user?.last_name} HooYia store`)
        const { t } = useTranslation();
        const [bgColor, setBgColor] = useState('');
        
        const colors = [
                "#FF5733",
                 "#18688d",
                 "#3357FF",
                 "#d83692",
                 "#a75415",
                 "rgb(8, 48, 48)",
        ];
        useEffect(()=>{
                const randomColor = colors[Math.floor(Math.random() * colors.length)]
                setBgColor(randomColor);
        }, [])
        const switchToSelling = () => {
            if (user?.user.is_seller_account) {
                    navigate('/', {state: true})
                    setSellerReload(true)
                    setIsSellerAccount(true) 
            }else {
                    navigate('/seller-onboarding')
            }
        }
        
return (
<div className="profile">
    <div className="container-body">
        <header className="bloc info-bloc">
            <div className="left-bloc">
            {
                                        user?.user?.profile?.pp 
                                        ?
                                        <img src={AWSbaseURL + (user?.user?.profile?.pp) } alt={ user?.user?.first_name+'profile pic' }  className='profile-pic'/>
                                        :
                                        <div className="initials profile-pic" style={{backgroundColor: bgColor}}>
                                            <p>{ (user?.user?.first_name).charAt(0) }  { (user?.user?.last_name).charAt(0)  }</p>
                                        </div>
                                }
                <div className="profile-info">
                    <h1>{ user?.user?.first_name + ' ' + user?.user?.last_name }</h1>
                </div>
            </div>
        </header>

        {/* first grid of informations */}
        <section className="section info-bloc">
            <div className="section-header">
                <h2>{t('seller_profile_page.personal_information.label')}</h2>
                <button className="btn edit">{t('seller_profile_page.edit')}</button>
            </div>
            <div className="content">
                <div className="first-grid grid">
                    {/* <!-- first row --> */}
                    <div className="row">
                        <div className="info">
                            <label for="item">{t('seller_profile_page.personal_information.first_name')}</label>
                            <h3>{ user?.user?.first_name }</h3>
                        </div>
                        <div className="info">
                            <label for="item">{t('seller_profile_page.personal_information.last_name')}</label>
                            <h3>{ user?.user?.last_name }</h3>
                        </div>
                        <div className="info">
                            <label for="item">{t('seller_profile_page.personal_information.country')}</label>
                            <h3>{(user?.profile?.country__name) ? (user?.profile?.country__name): '--------'}</h3>
                        </div>
                    </div>
                    {/* <!-- second row --> */}
                    <div className="row">
                        <div className="info">
                            <label for="item">{t('seller_profile_page.personal_information.email')}</label>
                            <h3>{ (user?.user?.email) ? (user?.user?.email) : '-------'}</h3>
                        </div>
                        <div className="info">
                            <label for="item">{t('seller_profile_page.personal_information.phone')}</label>
                            <h3>{ (user?.user?.phone) ? (user?.user?.phone) : '-------' }</h3>
                        </div>
                    </div>
                </div>
                {/* third row */}
                    <div className="row">
                        <div className="bio info">
                            <label htmlFor="item">{t('seller_profile_page.personal_information.bio')}</label>
                            <h3 className='bio_input'>{(user?.profile?.bio) ? (user?.profile?.bio): '--------'}</h3>
                        </div>
                    </div>
            </div>
        </section>
        {/* second grid of informations */}
        <section className="section info-bloc">
            {/* grid's header */}
            <div className="section-header">
                <h2>{t('seller_profile_page.address_information.label')}</h2>
                <button className="btn edit">{t('seller_profile_page.edit')}</button>
            </div>
            {/* grid content */}
            <div className="content">
                <div className="grid">
                    {/* grid's row */}
                    <div className="row">
                        <div className="info">
                            <label>{t('seller_profile_page.address_information.po_box')}</label>
                            <h3>{(user?.profile?.po_box) ? (user?.profile?.po_box): '--------'}</h3>
                        </div>
                        <div className="info">
                            <label>{t('seller_profile_page.address_information.city')}</label>
                            <h3>
                                {(user?.profile?.city) ? (user?.profile?.city): '--------'}
                            </h3>
                        </div>
                    </div>
                    {/* grid's row */}
                    <div className="row">
                        <div className="info">
                            <label>{t('seller_profile_page.address_information.main_phone_number')}</label>
                            <h3>{(user?.profile?.main_phone_number) ? (user?.profile?.main_phone_number): '--------'}</h3>
                        </div>
                        <div className="info">
                            <label>{t('seller_profile_page.address_information.alt_phone_number')}</label>
                            <h3>{(user?.profile?.alt_phone_number) ? (user?.profile?.alt_phone_number): '--------'}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div className="user_profile">
        <div className='profile_body'>
            {(ebooks.length !== 0) ? 
                <div className='container'>
                    <h3 className='title_profile'>{ t("alerts.store_title") }</h3>
                        <ProductGrid products={ebooks} isloading={isloading} />
                </div>
                :
                    <div className="no-books">
                        <p>{t('seller_profile_page.no_book_message')}</p>
                        <div className="add-books">
                            <p onClick={()=>{switchToSelling()}}>{t('seller_profile_page.add_book')}</p>
                        </div>
                    </div>
            }
        </div>
    </div>
</div>
  )
}
export default UserProfile
