import React, { useState, useEffect } from 'react'
import './Authenticate.css'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BsGift } from 'react-icons/bs'
import FacebookButton from './OAuth/FaceBookButton'
import GoogleButton from './OAuth/GoogleButton'
import { postRequest } from '../../../Resquest'
import { ReactComponent as ForgotPasswordSvg } from '../../../Assets/img/forgot_password_svg.svg';
import { updateMetaTags } from '../../../lib/withSEO'

const ForgotPassword = () => {

	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const navigate = useNavigate()

	const { t } = useTranslation()

	const sendForm = async () => {
		const userData = {
			"login": email
		}

		const response = await postRequest('/auth/send-reset-password-link/', userData)
		response.code === "ERR_BAD_REQUEST" && alert(response.response.data.detail)
		response.statusText === "OK" && navigate("/password-reset-link-sent")
	}

	const seoForgotPassword = {
		title: "Mot de passe oublié | HooYia e-book store",
		description:
		  "Réinitialisez votre mot de passe en toute simplicité sur HooYia. Suivez les étapes pour récupérer l'accès à votre compte.",
		keywords: "mot de passe oublié, réinitialisation, hooyia, compte",
		ogTitle: "Mot de passe oublié | HooYia",
		ogDescription:
		  "Procédez à la réinitialisation de votre mot de passe et retrouvez l'accès à votre compte sur HooYia.",
		ogImage: "https://www.e-hooyia.com/assets/forgot-password-og.jpg",
		twitterTitle: "Mot de passe oublié | HooYia",
		twitterDescription:
		  "Suivez les instructions pour réinitialiser votre mot de passe sur HooYia.",
		canonicalUrl: "https://www.e-hooyia.com/forgot-password",
		noindex: false,
	  };

	useEffect(() => {
		updateMetaTags(seoForgotPassword)
	  }, [])


	const handleFormSubmit = (e) => {
		e.preventDefault();
		sendForm()
	}

	const alert = (msg) => {
		setMessage(msg)
	}

	return (
		<div className='forgot_password_n auth_n'>
			<div className='authenticate'>
				<div className='authenticate__content'>
					<div className='authenticate__content_container'>
						<h3>{ t("auth_title.reset_password") }</h3>
						<div className='mobile-image'>
							{/* <img src={Mobile} alt='' /> */}
						</div>
						<form method="post" onSubmit={handleFormSubmit} autoComplete="off">
							<div className={`alert ${message ? 'showAlert' : ''}`}>{message}</div>

							<div className="inputField">
								<input type="text" onChange={e => setEmail(e.target.value)} name="email" value={email} required />
								<label className="label" htmlFor="email"><span className="labelContent">{ t('signupPage.email') }</span></label>
							</div>

							<div className="buttonWrapper">
								<button type="submit" className="submitBtn">{ t('reset_password.btn') }</button>
							</div>
							<div className='back_to_login'><Link to='/login' className='back_to_login'>{ t('reset_password.back_to_login') }</Link></div>
							
						</form>

					</div>
					<div className='signup_convince'>
						<div className='convince_item'>
							<div className='title'>
								<span className='iconRegister'> <BsGift /> </span>
								<span> { t('reset_password.support.title') }   </span>
							</div>
							<div className='forgot_password_story'>{ t('reset_password.support.text') } </div>
						</div>
						<div className='convince_item'>
							<div className='svg_login forgotpassword_page_specific'>
								<ForgotPasswordSvg />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ForgotPassword