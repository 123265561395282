import React, { useEffect } from 'react'
import './Guide.css'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { ReactComponent as LandingSVG } from './Landing.svg'
import ReactPlayer from "react-player"

import img_one from './img_one.jpg'
import img_two from './img_two.jpg'
import img_tre from './img_tre.jpg'
import { AnimatePresence, motion } from 'framer-motion';
import { updateMetaTags } from '../../../../lib/withSEO'

const Guide = () => {

        const seoTourGuide = {
                title: "Guide de la plateforme | HooYia e-book store",
                description:
                  "Découvrez comment utiliser HooYia grâce à notre guide détaillé pour une navigation optimale.",
                keywords: "guide, tutoriel, hooyia, e-book, utilisation",
                ogTitle: "Guide de la plateforme | HooYia",
                ogDescription:
                  "Apprenez à naviguer et à profiter pleinement de HooYia grâce à notre guide complet.",
                ogImage: "https://www.e-hooyia.com/assets/guide-og.jpg",
                twitterTitle: "Guide de la plateforme | HooYia",
                twitterDescription:
                  "Toutes les astuces pour utiliser HooYia e-book store efficacement.",
                canonicalUrl: "https://www.e-hooyia.com/tour/guide",
                noindex: false,
              };

        useEffect(() => {
                updateMetaTags(seoTourGuide);
        }, []);
  return (
    <div className='guide_page'>
        <div className='container'>
                <Landing />
                <Vid />
                <Blog />
                <GuideCard />
        </div>
    </div>
  )
}

const variants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1
        }
      };

      const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.4,
            staggerChildren: 0.1
          }
        }
      };
      
      const item = {
        hidden: { x: 50, opacity: 0 },
        visible: {
          x: 0,skew: -10,
          opacity: 1
        }
      };
      
    

export default Guide
const Landing = () => {
        useEffect(() => {
          
        }, []);
        return (
                <div className='guide_landing'>
                        <div className='content'>
                                <h2>Seamless navigation and instant access, dive into the next literacy adventure</h2>
                                <p>With a community of over 3 million users across Africa </p>
                        </div>
                        <div className='graphics'>
                                <LandingSVG />
                                <div className='landing_hover'>
                                        <div className='skew_landing'>
                                                <motion.ul
                                                        variants={container}
                                                        initial="hidden"
                                                        animate="visible"
                                                        >
                                                        <motion.div 
                                                        variants={item}
                                                        className='guide_country unskew_landing'
                                                        >
                                                                <div className='bubble_flag'><span className={`flag flag-icon flag-icon-cm`} /></div>
                                                                <div className='country_name'>
                                                                        <p>Cameroon</p>
                                                                        <p className='small_text_country'>e-hooyia héberge une large communauté d'écrivains et de lecteurs. </p>
                                                                </div>
                                                        </motion.div>
                                                        <motion.div 
                                                        variants={item}
                                                        className='guide_country'
                                                        >
                                                                <div className='bubble_flag'><span className={`flag flag-icon flag-icon-ng`} /></div>
                                                                        <div className='country_name'><p>Nigeria</p>
                                                                        <p className='small_text_country'>Many young authors publish numerous books and documents there daily.</p>
                                                                </div>
                                                        </motion.div>
                                                        <motion.div 
                                                                variants={item}
                                                                className='guide_country'
                                                        >
                                                                <div className='bubble_flag'><span className={`flag flag-icon flag-icon-zw`} /></div>
                                                                        <div className='country_name'><p>Zimbabwe</p>
                                                                        <p className='small_text_country'>A land of talent and creativity, whose literary works are to be discovered on our platform.</p>
                                                                </div>
                                                        </motion.div>

                                                </motion.ul>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}
const Vid = () => {
        
        return (
                <div className='tour_vid'>
                        <div className='description'>
                                <p style={{ fontWeight: 200 }}>Your future is at your figertips</p>
                                <p>Discover a world of captivating e-book. Out online platform offers an extensive collection of diverse books, catering to all reading preferences</p>
                        </div>
                        <div className='video_wrapper'>
                                <div className='tour_vid'>
                                        <ReactPlayer
                                                url="https://www.youtube.com/watch?v=fJ41I81VeK0"
                                                className='react-player'
                                                playing
                                                width='100%'
                                                height='100%'
                                        />
                                </div>
                        </div>
                </div>
        )
}
 
const Blog = () => {
        return (
                <div className='guide_blog'>
                        <div className='guide_title'>Explore Interesting stories about HooYia Ebooks</div>
                        <div className='blog_grid'>
                                <div className='blog_item'>
                                        <div className='blog_image'>
                                                <img src={img_one} alt='girl reading book' />
                                        </div>
                                        <div className='description'>
                                                <div className='moreLink'>
                                                        <BsArrowRight />
                                                </div>
                                                <div className='blog_desc_text'>
                                                        <Link to='#'>Some blog title without content</Link>
                                                        This introductory course will provide you with the foundational skillset required to write computer programs. 
                                                </div>
                                        </div>
                                </div>
                                <div className='blog_item'>
                                        <div className='blog_image'>
                                                <img src={img_two} alt='girl reading book' />
                                        </div>
                                        <div className='description'>
                                                <div className='moreLink'>
                                                        <BsArrowRight />
                                                </div>
                                                <div className='blog_desc_text'>
                                                        <Link to='#'>Some blog title without content</Link>
                                                        This introductory course will provide you with the foundational skillset required to write computer programs. 
                                                </div>
                                        </div>
                                </div>
                                <div className='blog_item'>
                                        <div className='blog_image'>
                                                <img src={img_tre} alt='girl reading book' />
                                        </div>
                                        <div className='description'>
                                                <div className='moreLink'>
                                                        <BsArrowRight />
                                                </div>
                                                <div className='blog_desc_text'>
                                                        <Link to='#'>Some blog title without content</Link>
                                                        This introductory course will provide you with the foundational skillset required to write computer programs. 
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}

const GuideCard = () => {
        return (
                <div className='guide_card'>
                        <div className='numbers_section'>
                                <div className='title number_big'>86%</div>
                                <div className='description'>
                                        of publishers and writers are amazed and they enjoy using this HooYia to manage their clients
                                </div>
                        </div>
                        <div className='link_section'>
                                <div className='get_started_sec'>Join us today and enjoy all benefits of the best eBook system in Africa</div>
                                <Link to='/login' className='btn'>Get Started</Link>
                        </div>
                </div>
        )
}