import React from 'react'
import { useTranslation } from 'react-i18next'
import { values } from './location/values'

const WhatWeDoSection = () => {
  const { t } = useTranslation()
  const Values = values(t);
  return (
    <div className='WhatWeDoSection '>
            <h2 className='section_title '>{ t('new_about_title', {defaultValue: 'Our Values'})}</h2>
            <div className='card-grid'>
            {Values.map((card, index) => (
                index === 1 ? (
                  <a target='_blank' rel='noreferrer noopener' href='https://hooyia.net/fr/training/' key={card.id} className='card-item'>
                    <img src={card.image} alt={card.title} />
                    <h2>{card.title}</h2>
                    <p>{card.content}</p>
                  </a>
                ) : (
                  <div key={card.id} className='card-item'>
                    <img src={card.image} alt={card.title} />
                    <h2>{card.title}</h2>
                    <p>{card.content}</p>
                  </div>
                )
            ))}
            </div>
    </div>
  )
}

//   return (
//     <div className='WhatWeDoSection'>
//       <h2 className='section_title'>{t('about_page.values.main_title')}</h2>
//       <div className='card-grid'>
//         {values.map((card) => (
//           <div key={card.id} className='card-item'>
//             <img src={card.image} alt={card.title} />
//             <h2>{card.title}</h2>
//             <p>{card.content}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

export default WhatWeDoSection;
