import React, { useEffect, useRef } from 'react'

import { Player } from '@lottiefiles/react-lottie-player';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateMetaTags } from '../../../lib/withSEO';

const PasswordResetLinkSent = () => {
    const player = useRef('');

    const { t } = useTranslation();

    const seoPasswordResetLinkSent = {
        title: "Lien de réinitialisation envoyé | HooYia e-book store",
        description:
          "Un lien de réinitialisation a été envoyé à votre adresse email. Veuillez vérifier votre boîte de réception.",
        keywords: "lien réinitialisation, mot de passe, hooyia, email",
        ogTitle: "Lien de réinitialisation envoyé | HooYia",
        ogDescription:
          "Consultez votre email pour suivre la procédure de réinitialisation de votre mot de passe sur HooYia.",
        ogImage: "https://www.e-hooyia.com/assets/password-reset-link-sent-og.jpg",
        twitterTitle: "Lien de réinitialisation envoyé | HooYia",
        twitterDescription:
          "Vérifiez votre boîte de réception pour le lien de réinitialisation de votre mot de passe.",
        canonicalUrl: "https://www.e-hooyia.com/password-reset-link-sent",
        noindex: false,
      };

      useEffect(() => {
        updateMetaTags(seoPasswordResetLinkSent);
      }, [])

    const endAnimatiion = () => {
        player.current.setSeeker(120, false)
    }
    return (
        <div className='email_verify'>
            <div className='container'>
                <div className='email_verify_loader'>
                    <Player
                        onEvent={event => {
                            if (event === 'complete') endAnimatiion()

                        }}
                        autoplay
                        direction={120}
                        ref={player}
                        src="https://assets4.lottiefiles.com/packages/lf20_bx4oomul.json"
                        style={{ height: '150px', width: '150px' }}
                    >
                        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
                </div>
                <p className='verify_email_text'> {t('signupPage.reset_link_sent')} </p>
                <p className='continue_browsing'>
                    <Link to='/ebooks?auth=false'><BsArrowLeft /> {t('signupPage.continue_browsing')} </Link>
                </p>
            </div>
        </div>
    )
}

export default PasswordResetLinkSent
