import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiDownload, FiShare2 } from 'react-icons/fi'
import Rating from '../Addons/Rating'
import { AnimatePresence, motion } from 'framer-motion'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { VscChromeClose, VscFeedback } from 'react-icons/vsc'
import { useTranslation } from 'react-i18next'
import { GoComment } from "react-icons/go";
import { AiFillStar } from 'react-icons/ai'
import { postRequest } from '../../Resquest'
import { ReactComponent as ThreeDots } from '../../Assets/Loaders/ThreeDots.svg'
import DefaultCover from '../../Assets/img/HooYia.png'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const variants = {
        hidden: {
                y: "10vh",
                opacity: 0,
                transition: {
                        duration: 0.1,
                        type: "spring",
                        damping: 25,
                        stiffness: 500,
                }
        },
        visible: {
                y: "0vh",
                opacity: 1,
                transition: {
                        duration: 0.1,
                        type: "spring",
                        damping: 25,
                        stiffness: 500,
                }
        },
        exit: {
                y: "10vh",
                opacity: 0,

        }
}

const MyBook = ({ book }) => {
        const navigate = useNavigate()
        const [copied, setCopied] = useState(false)
        const [showCommentModal, setShowCommentModal] = useState(false)
        const [sendingReview, setSendingReview] = useState(false)
        const [review, setReview] = useState('')
        const [reviewed, setReviewed] = useState(false)
        const [just_reviewed, setJust_reviewed] = useState(false)
        const [stars, setStars] = useState(new Array(5).fill(0));
        const [bookShareLink, setBookShareLink] = useState('')

        const handleRateStar = (index) => {
                setStars((prevStars) => {
                        return prevStars.map((star, i) => (i === index ? (star === 0 ? 1 : 0) : star));
                });
        };


        const commentModal = (e) => {
                e.stopPropagation()
                setShowCommentModal(true)
        }
        const shareLink = (e) => {
                e.stopPropagation()
                setBookShareLink(`e-hooyia.com/ebooks/${book?.slug}`)
                navigator.clipboard.writeText(book?.doc)
                setCopied(true)
                setTimeout(() => {
                        setCopied(false)
                }, 3000);
                // console.log(book)
        }

        const submit_review = async () => {
                setSendingReview(true)
                const request = {
                        "rating": stars.reduce((acc, star) => acc + star, 0),
                        "comment": review,
                        "slug": book?.slug
                }
                console.log(request);
                const response = await postRequest("/public/ebook/review/", request)
                response.status === 201 && saveData(response.data)
        }

        const saveData = ({ data }) => {
                setSendingReview(false)
                setShowCommentModal(false)

                setTimeout(() => {
                        setJust_reviewed(true)
                }, 500);

                setTimeout(() => {
                        setJust_reviewed(false)
                }, 3000);
        }

        const { t } = useTranslation()

        return (
                <>
                        <div draggable className='book my_book_item'>
                                <div className='book_profile_pic'>
                                        {
                                                book?.cover
                                                        ?
                                                        <img src={book?.cover} alt={book?.name} />
                                                        :
                                                        <img src={DefaultCover} alt="Hooyia Book" />
                                        }
                                </div>
                                <div className='book_profile_desc'>
                                        <h2 className='book_name'>{book?.name}</h2>
                                        <p className='bold my_book_author'>{t("randoms.by")} : {book?.author}</p>
                                        <p onClick={(e) => shareLink(e)} className='small'><span className='icon'><FiShare2 /></span>{t("randoms.share")}</p>
                                        <p onClick={commentModal} className='small review_anchor'><span className='icon review_icon'><GoComment /></span>{t("randoms.review")}</p>
                                        {/* after correct the croos origin issue, use the below link */}
                                        <Link to={`/pdf/${encodeURIComponent(book?.doc)}`} className='small'>
                                                <FiDownload /> {t("randoms.download")}
                                        </Link>
                                </div>
                                {/* <div className='leave_review'>{ reviewed? <Rating value = {book?.avg_rating} /> : "Leave a review"}</div> */}
                                <span className={`color-dot ${reviewed ? 'color-dot-reviewed' : ''}`}></span>
                        </div>
                        {
                                showCommentModal && (
                                        <div className='review_modal'>
                                                <div className='review_modal_content'>
                                                        <span onClick={() => setShowCommentModal(false)} className='close'><VscChromeClose /></span>
                                                        <h2 className='book_name'>{book?.name}</h2>
                                                        <div className='review_modal_rating'>
                                                                <h4>{t("review.review_title")}</h4>
                                                                <div className='review_modal_stars'>
                                                                        {stars.map((star, index) => (
                                                                                <span key={index} onClick={() => handleRateStar(index)}>
                                                                                        <AiFillStar className={star === 1 ? 'selectedStar' : ''} />
                                                                                </span>
                                                                        ))}
                                                                </div>
                                                        </div>
                                                        <div className='review_modal_comment'>
                                                                <h4>{t("review.comment_title")}</h4>
                                                                <textarea value={review} onChange={e => setReview(e.target.value)} rows={4} placeholder={t("review.placeholder")}></textarea>
                                                        </div>
                                                        <button className='save_review_btn' onClick={submit_review}>
                                                                <span>{t("review.comment")}</span>
                                                                {
                                                                        sendingReview && <span className='loadingButton'><ThreeDots /></span>
                                                                }
                                                        </button>
                                                </div>
                                        </div>
                                )
                        }
                        <AnimatePresence
                                initial={false}
                                mode='wait'
                        >
                                {
                                        copied && (
                                                <div className='book_modalWrapper'>
                                                        <motion.div
                                                                className='book_notify_modal'
                                                                variants={variants}
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="exit"
                                                        >
                                                                <span className='checkmark_book'><BsFillCheckCircleFill /></span>
                                                                <p className='modal_notify_text'>{t("randoms.copy_clipboard")}</p>
                                                                <span onClick={() => setCopied(false)}><VscChromeClose /></span>
                                                        </motion.div>
                                                </div>
                                        )
                                }
                                {
                                        just_reviewed && (
                                                <div className='book_modalWrapper'>
                                                        <motion.div
                                                                className='book_notify_modal'
                                                                variants={variants}
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="exit"
                                                        >
                                                                <span className='checkmark_book'><BsFillCheckCircleFill /></span>
                                                                <p className='modal_notify_text'>{t("randoms.review_sent")}</p>
                                                                <span onClick={() => setCopied(false)}><VscChromeClose /></span>
                                                        </motion.div>
                                                </div>
                                        )
                                }

                        </AnimatePresence>
                </>
        )
}

export default MyBook