import React, { useEffect, useRef } from 'react'

import { Player } from '@lottiefiles/react-lottie-player';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { postRequest } from '../../../Resquest';
import { useTranslation } from 'react-i18next';
import { updateMetaTags } from '../../../lib/withSEO';

const EmailVerified = () => {
    const navigate = useNavigate()
    const player = useRef('');

    const endAnimatiion = () => {
        player.current.setSeeker(120, false)
    }

    const [queryParameters] = useSearchParams()
    const userId = queryParameters.get("user_id") 
    const timestamp = queryParameters.get("timestamp")
    const signature = queryParameters.get("signature")

    const seoEmailVerified = {
        title: "Email vérifié | HooYia e-book store",
        description:
          "Votre adresse email a été vérifiée avec succès. Vous pouvez désormais accéder à toutes les fonctionnalités de HooYia.",
        keywords: "email vérifié, hooyia, compte, activation",
        ogTitle: "Email vérifié | HooYia",
        ogDescription:
          "Félicitations, votre adresse email est confirmée. Profitez pleinement de HooYia e-book store.",
        ogImage: "https://www.e-hooyia.com/assets/email-verified-og.jpg",
        twitterTitle: "Email vérifié | HooYia",
        twitterDescription:
          "Votre adresse email est validée. Connectez-vous et explorez HooYia.",
        canonicalUrl: "https://www.e-hooyia.com/verify-user",
        noindex: false,
      };
      useEffect(() => {
        updateMetaTags(seoEmailVerified)
      }, []);

    const sendRequest = async() => {
        const dataset = {
            "user_id":userId,
            "timestamp":timestamp,
            "signature":signature
        }

        const response = await postRequest('/auth/verify-user/', dataset)
        response?.statusText !== "OK" && navigate('/login')
    }

    useEffect(() => {
      sendRequest()
    }, [])
    
    const { t } = useTranslation()
    return (
        <div className='email_verify'>
            <div className='container'>
                <div className='email_verify_loader'>
                    <Player
                        onEvent={event => {
                            if (event === 'complete') endAnimatiion()

                        }}
                        autoplay
                        direction={120}
                        ref={player}
                        src="https://assets4.lottiefiles.com/packages/lf20_bx4oomul.json"
                        style={{ height: '150px', width: '150px' }}
                    >
                        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
                </div>
                <p className='verify_email_text'>{ t("alerts.confirm_signup") }</p>
                <p className='continue_browsing'>
                    <Link to='/ebooks?auth=false'><BsArrowLeft />{ t("alerts.login") }</Link>
                </p>
            </div>
        </div>
    )
}

export default EmailVerified
