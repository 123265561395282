import React from 'react'
import { BsGift } from 'react-icons/bs'

import { ReactComponent as HelpSvg } from '../../../Assets/img/help_svg.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withSEO } from '../../../lib/withSEO';

const RequestSubmitted = () => {
    const { t } = useTranslation()

  return (
    <div className='container'>
        <div className='request_sent_confirmation'>
            <h4 className='rs_heading'>{ t("faqs.request.title") }</h4>
            <div className='rs_svg'>
                    <HelpSvg />
            </div>

            <div className='rs_notice'>
            { t("faqs.request.desc_one") }
                <p>{ t("faqs.request.desc_two") }</p>
            </div>
            <div className='back_to_login'><Link to='/login' className='back_to_login'>{ t('auth_title.back_to_login') }</Link></div>
        </div>
    </div>
  )
}

export const seoMessageSubmitted = {
  title: "Message envoyé | HooYia e-book store",
  description:
    "Votre message a bien été envoyé. Nous vous répondrons dans les plus brefs délais. Merci de nous avoir contacté !",
  keywords: "message envoyé, confirmation, hooyia, support, e-book",
  ogTitle: "Message envoyé | HooYia",
  ogDescription:
    "Votre demande a été prise en compte. Consultez votre email pour plus de détails.",
  ogImage: "https://www.e-hooyia.com/assets/message-submitted-og.jpg",
  twitterTitle: "Message envoyé | HooYia",
  twitterDescription:
    "Nous avons bien reçu votre message. Merci de votre confiance sur HooYia e-book store.",
  canonicalUrl: "https://www.e-hooyia.com/help/message-submitted",
  noindex: false,
};


export default withSEO ( RequestSubmitted, seoMessageSubmitted )