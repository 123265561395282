import React, { useContext, useEffect, useState } from 'react'
import '../Auth/Authenticate.css'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BsGift } from 'react-icons/bs'
import axios from 'axios'

import { ReactComponent as ThreeDots } from '../../../Assets/Loaders/ThreeDots.svg'
import { ReactComponent as HelpSvg } from '../../../Assets/img/help_svg.svg';
import { postRequest } from '../../../Resquest'
import { UserContext } from '../../../Context/AuthContext'
import { withSEO } from '../../../lib/withSEO'

const Contact = () => {
        const [firstName, setFirstName] = useState('');
        const [lastName, setLastName] = useState('');
        const [phoneNumber, setPhoneNumber] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');
        
        const [isLoading, setIsLoading] = useState(false);
        const [subject, setSubject] = useState('')

        const [user, setUser] = useContext(UserContext)
        const [disableInputs, setDisableInputs] = useState(false)

        
        const navigate = useNavigate();

        useEffect(() => {
                if(user?.user){
                        setFirstName(user?.user?.first_name)
                        setLastName(user?.user?.last_name)
                        setEmail(user?.user?.email)
                        setDisableInputs(true)
                }
        }, [])
        

        const data = {
                "full_name": `${firstName} ${lastName}`,
                "email": email,
                "subject": subject,
                "message": message
        }
        
        const sendForm = async() => {
                setIsLoading(true)
                const res = await postRequest('/auth/contact/', data)
                if(res.status === 201){
                        reRoute()
                }
                setIsLoading(false)
        }


        const validateForm = () => {
                if (!isValidEmail(email)) {
                        alert(t("faqs.request.email_alert"))
                        return false
                } 

                setFirstName(firstName.trim())
                setLastName(lastName.trim())
                setPhoneNumber(phoneNumber.trim())
                setEmail(email.trim())
                setMessage(message.trim())
                
                return true

        }

        /**
         * Checks and verifies user email
         * @param {String} email email to verify
         * @returns boolean if email is valid
         */
        function isValidEmail(email) {
                var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(email);
        }

        /**
         * Redirect user to display success message 
         */
        const reRoute = () => {
                clearForm()
                navigate('/help/message-submitted')
        }

        const clearForm = () => {
                setFirstName('')
                setLastName('')
                setPhoneNumber('')
                setEmail('')
                setMessage('')
        }

        const submitForm = (e) => {
                e.preventDefault();
                const formValidated = validateForm()

                if(formValidated){
                   sendForm()
                }
        }

        const {t} = useTranslation()
  return (
    <div>
        <div className='auth_n'>
                <div className='authenticate'>
                        <div className='authenticate__content'>
                                <div className='authenticate__content_container'>
                                        <h3>{ t('auth_title.contact_us') }</h3>
                                        <form method="post" onSubmit={submitForm} autoComplete="off">

                                                <div className="inputField">
                                                        <input type="text" name="fname" onChange={e => setFirstName(e.target.value)}  value={firstName} required/>
                                                        <label className="label" htmlFor="fname"><span className="labelContent">{ t('signupPage.fname') }</span></label>
                                                </div>

                                                <div className="inputField">
                                                        <input type="text" name="lname" onChange={e => setLastName(e.target.value)} value={lastName} required/>
                                                        <label className="label" htmlFor="lname"><span className="labelContent">{ t('signupPage.lname') }</span></label>
                                                </div>

                                                <div className="inputField">
                                                        <input type="text" name="email" onChange={e => setEmail(e.target.value)} value={email} required/>
                                                        <label className="label" htmlFor="email"><span className="labelContent">{ t('signupPage.email') }</span></label>
                                                </div>

                                                <div className="inputField">
                                                        <input type="text" name="subject" onChange={e => setSubject(e.target.value)} value={subject} required/>
                                                        <label className="label" htmlFor="subject"><span className="labelContent">{ t('signupPage.subject') }</span></label>
                                                </div>

                                                <div className="inputField message">
                                                        <textarea type="text" name="textArea" cols="0" onChange={e => setMessage(e.target.value)} value={message} required/>
                                                        <label className="label" htmlFor="textArea"><span className="labelContent">Message</span></label>
                                                </div>
                                                
                                                <div className="buttonWrapper">
                                                        <button type="submit" className="submitBtn">
                                                                <span className='buttonText'>{ t('signupPage.submit') } </span>
                                                                {
                                                                isLoading && <span className='loadingButton'><ThreeDots /></span>
                                                                }
                                                                
                                                        </button>
                                                </div>
                                                <div className='back_to_login'><Link to='/login' className='back_to_login'>{ t('auth_title.back_to_login') }</Link></div>
                                        </form>

                                </div>
                                <div className='signup_convince'>
                                        <div className='convince_item'>
                                                <div className='title'> 
                                                        <span className='iconRegister'> <BsGift /> </span>
                                                        <span> Features </span>
                                                </div>
                                                <p>{ t("faqs.feature_one") }<br /><br /> { t("faqs.feature_two") }</p>
                                        </div>
                                        <div className='convince_item'>
                                                <div className='svg_login'>
                                                        <HelpSvg />
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
    </div>
  )
}

export const seoContactSupport = {
        title: "Contactez le support | HooYia e-book store",
        description:
          "Besoin d'aide ? Contactez notre support client pour toute question ou assistance sur HooYia.",
        keywords: "contact, support, aide, hooyia, e-book",
        ogTitle: "Contact Support | HooYia",
        ogDescription:
          "Notre équipe est à votre écoute pour répondre à toutes vos questions sur HooYia.",
        ogImage: "https://www.e-hooyia.com/assets/contact-support-og.jpg",
        twitterTitle: "Contact Support | HooYia",
        twitterDescription:
          "Obtenez l'aide dont vous avez besoin en contactant le support client de HooYia.",
        canonicalUrl: "https://www.e-hooyia.com/help/contact-support",
        noindex: false,
      };
      
export default withSEO ( Contact, seoContactSupport )
