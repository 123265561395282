
import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { tabTitle } from '../..'
import './privacy.css'
import { updateMetaTags } from '../../lib/withSEO'

const RefundPolicy = () => {
  const [lang, setLang] = useState('fr')

  const seoRefundPolicy = {
    title: "Politique de remboursement | HooYia e-book store",
    description:
      "Informez-vous sur notre politique de remboursement afin de connaître vos droits et procédures en cas de besoin.",
    keywords: "remboursement, politique, hooyia, e-book, conditions",
    ogTitle: "Politique de remboursement | HooYia",
    ogDescription:
      "Découvrez les conditions et procédures de remboursement sur HooYia e-book store.",
    ogImage: "https://www.e-hooyia.com/assets/refund-policy-og.jpg",
    twitterTitle: "Politique de remboursement | HooYia",
    twitterDescription:
      "Informez-vous sur les modalités de remboursement sur HooYia.",
    canonicalUrl: "https://www.e-hooyia.com/refund-policy",
    noindex: false,
  };

  // tabTitle("Politique de confidentialité de HooYia")
  useEffect(() => {
    updateMetaTags(seoRefundPolicy);
    setLang(i18next.language)
  }, [])
  
  return (
    <>
    {
      lang === 'fr'
      ?
      <RefundPolicyFR />
      :
      <RefundPolicyEN />
    }
    </>
  )
}

export default RefundPolicy




const RefundPolicyEN = () => {
  return (
    <div className='container refund_policy'>
      <h1>REFUND POLICY</h1>
      <p className='date_update'>Last updated on August 20, 2024</p>

      <p>This Refund Policy for HooYia ("we," "our," or "us") outlines the conditions under which you can request a refund for digital book purchases made through our platform. By using our services ("Services"), including purchasing e-books from our website (https://e-hooyia.com), you agree to the terms outlined in this policy.</p>

      <h2>1. REFUND REQUESTS</h2>
      <p>You may request a refund for a purchase within 3 days of the purchase date. To be eligible for a refund, the following conditions must be met:</p>
      <ul>
        <li>The request is made within 3 days of the purchase date.</li>
        <li>A refund request will only be considered if there are significant issues with the content, such as incomplete or non-functional files.</li>
      </ul>

      <h2>2. REFUND PROCESS</h2>
      <p>If you encounter any issues with your purchase, such as incomplete or non-functional content, you can request a refund by following these steps:</p>
      <ul>
        <li>Contact us at <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a> to notify us of the issue and initiate the refund process.</li>
        <li>Provide details about the issue and your purchase information.</li>
        <li>We will review your request and process the refund within 1 business day if approved.</li>
      </ul>

      <h2>3. PAYMENT ISSUES</h2>
      <p>If you experience any issues with the payment or transaction process, such as incorrect charges or failed transactions, please contact us immediately for verification and resolution:</p>
      <ul>
        <li>Email us at <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a> with details of the issue.</li>
        <li>Provide any relevant transaction information and screenshots, if available.</li>
        <li>We will investigate the issue and provide a resolution, which may include issuing a refund if appropriate.</li>
      </ul>

      <h2>4. EXCLUSIONS</h2>
      <p>Refund requests will not be accepted under the following circumstances:</p>
      <ul>
        <li>Requests made after 3 days from the purchase date.</li>
        <li>Dissatisfaction with the content of the e-book after purchase, provided the file is complete and functional.</li>
      </ul>

      <h2>5. PAYMENT METHODS AND CURRENCIES</h2>
      <p>We accept all payment methods supported by Flutterwave. Refunds will be processed to the original payment method used for the purchase. For currency conversions, the exchange rate at the time of the refund will apply.</p>

      <h2>6. FILE FORMATS AND SOFTWARE</h2>
      <p>Our e-books are provided in PDF, DOCX, and DOC formats. It is the customer's responsibility to have appropriate software installed to read these file formats. We recommend using up-to-date PDF readers and word processing software to ensure compatibility.</p>

      <h2>7. DATA RETENTION AND ACCESS AFTER REFUND</h2>
      <p>Upon approval and processing of a refund, access to the e-book will be immediately revoked. We reserve the right to retain transaction records for legal and accounting purposes, but we will not retain copies of the refunded e-book content in the customer's account.</p>

      <h2>8. CONTACT US</h2>
      <p>If you have any questions or concerns about our refund policy, or if you need assistance with a refund request, please contact us at <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>

      <h2>9. LEGAL COMPLIANCE</h2>
      <p>This refund policy complies with the consumer protection laws of Cameroon, including:</p>
      <ul>
        <li>Law No. 2011/012 of 6 May 2011 on Consumer Protection in Cameroon</li>
        <li>Framework Law No. 2011/020 of 14 December 2011 on E-Commerce in Cameroon</li>
        <li>Law No. 2010/021 of 21 December 2010 Governing Electronic Communications in Cameroon</li>
      </ul>
      <p className='laws'>These laws ensure fair trade practices, protect consumer rights in digital transactions, and regulate electronic communications and e-commerce activities in Cameroon.</p>

      <hr />

      <p>Thank you for choosing HooYia. We are committed to ensuring a positive experience with our services and appreciate your understanding of our refund policy.</p>
    </div>
  )
}
  

const RefundPolicyFR = () => {
  return (
    <div className='container refund_policy'>
      <h1>POLITIQUE DE REMBOURSEMENT</h1>
      <p className='date_update'>Dernière mise à jour le 20 août 2024</p>

      <p>Cette Politique de Remboursement pour HooYia ("nous," "notre," ou "nos") décrit les conditions dans lesquelles vous pouvez demander un remboursement pour les achats de livres numériques effectués via notre plateforme. En utilisant nos services ("Services"), y compris l'achat de livres électroniques sur notre site web (https://e-hooyia.com), vous acceptez les termes décrits dans cette politique.</p>

      <h2>1. DEMANDES DE REMBOURSEMENT</h2>
      <p>Vous pouvez demander un remboursement pour un achat dans les 3 jours suivant la date d'achat. Pour être éligible à un remboursement, les conditions suivantes doivent être remplies :</p>
      <ul>
        <li>La demande est faite dans les 3 jours suivant la date d'achat.</li>
        <li>Une demande de remboursement ne sera considérée que s'il y a des problèmes significatifs avec le contenu, tels que des fichiers incomplets ou non fonctionnels.</li>
      </ul>

      <h2>2. PROCESSUS DE REMBOURSEMENT</h2>
      <p>Si vous rencontrez des problèmes avec votre achat, tels qu'un contenu incomplet ou non fonctionnel, vous pouvez demander un remboursement en suivant ces étapes :</p>
      <ul>
        <li>Contactez-nous à <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a> pour nous informer du problème et initier le processus de remboursement.</li>
        <li>Fournissez des détails sur le problème et vos informations d'achat.</li>
        <li>Nous examinerons votre demande et traiterons le remboursement dans un délai d'un jour ouvrable si elle est approuvée.</li>
      </ul>

      <h2>3. PROBLÈMES DE PAIEMENT</h2>
      <p>Si vous rencontrez des problèmes avec le paiement ou le processus de transaction, tels que des frais incorrects ou des transactions échouées, veuillez nous contacter immédiatement pour vérification et résolution :</p>
      <ul>
        <li>Envoyez-nous un email à <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a> avec les détails du problème.</li>
        <li>Fournissez toute information de transaction pertinente et des captures d'écran, si disponibles.</li>
        <li>Nous enquêterons sur le problème et fournirons une résolution, qui peut inclure l'émission d'un remboursement si approprié.</li>
      </ul>

      <h2>4. EXCLUSIONS</h2>
      <p>Les demandes de remboursement ne seront pas acceptées dans les circonstances suivantes :</p>
      <ul>
        <li>Demandes faites après 3 jours à compter de la date d'achat.</li>
        <li>Insatisfaction quant au contenu du livre électronique après l'achat, à condition que le fichier soit complet et fonctionnel.</li>
      </ul>

      <h2>5. MÉTHODES DE PAIEMENT ET DEVISES</h2>
      <p>Nous acceptons toutes les méthodes de paiement prises en charge par Flutterwave. Les remboursements seront traités sur la méthode de paiement originale utilisée pour l'achat. Pour les conversions de devises, le taux de change au moment du remboursement s'appliquera.</p>

      <h2>6. FORMATS DE FICHIERS ET LOGICIELS</h2>
      <p>Nos livres électroniques sont fournis aux formats PDF, DOCX et DOC. Il est de la responsabilité du client d'avoir les logiciels appropriés installés pour lire ces formats de fichiers. Nous recommandons d'utiliser des lecteurs PDF et des logiciels de traitement de texte à jour pour assurer la compatibilité.</p>

      <h2>7. CONSERVATION DES DONNÉES ET ACCÈS APRÈS REMBOURSEMENT</h2>
      <p>Après approbation et traitement d'un remboursement, l'accès au livre électronique sera immédiatement révoqué. Nous nous réservons le droit de conserver les enregistrements de transaction à des fins légales et comptables, mais nous ne conserverons pas de copies du contenu du livre électronique remboursé dans le compte du client.</p>

      <h2>8. CONTACTEZ-NOUS</h2>
      <p>Si vous avez des questions ou des préoccupations concernant notre politique de remboursement, ou si vous avez besoin d'une assistance pour une demande de remboursement, veuillez nous contacter à <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>

      <h2>9. CONFORMITÉ LÉGALE</h2>
      <p>Cette politique de remboursement est conforme aux lois de protection des consommateurs du Cameroun, notamment :</p>
      <ul>
        <li>Loi n° 2011/012 du 6 mai 2011 relative à la protection du consommateur au Cameroun</li>
        <li>Loi-cadre n° 2011/020 du 14 décembre 2011 sur le commerce électronique au Cameroun</li>
        <li>Loi n° 2010/021 du 21 décembre 2010 régissant les communications électroniques au Cameroun</li>
      </ul>
      <p className='laws'>Ces lois garantissent des pratiques commerciales équitables, protègent les droits des consommateurs dans les transactions numériques et réglementent les activités de communication électronique et de commerce électronique au Cameroun.</p>

      <hr />

      <p>Merci d'avoir choisi HooYia. Nous nous engageons à garantir une expérience positive avec nos services et nous apprécions votre compréhension de notre politique de remboursement.</p>
    </div>
  )
}