import React, { useRef, useEffect } from 'react'

import { Player } from '@lottiefiles/react-lottie-player';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateMetaTags } from '../../lib/withSEO';

const SellerAccountSuccess = () => {
    const player = useRef('');

    const seoSellerAccountSuccess = {
        title: "Compte vendeur créé avec succès | HooYia e-book store",
        description:
          "Votre compte vendeur a été créé avec succès. Bienvenue dans la communauté des vendeurs HooYia !",
        keywords: "compte vendeur, succès, hooyia, e-book, inscription",
        ogTitle: "Compte vendeur créé | HooYia",
        ogDescription:
          "Félicitations ! Votre compte vendeur a été activé sur HooYia e-book store.",
        ogImage: "https://www.e-hooyia.com/assets/seller-account-success-og.jpg",
        twitterTitle: "Compte vendeur créé | HooYia",
        twitterDescription:
          "Bienvenue sur HooYia, votre compte vendeur a été créé avec succès.",
        canonicalUrl: "https://www.e-hooyia.com/seller_account_success",
        noindex: false,
      };

    useEffect(() => {
        updateMetaTags(seoSellerAccountSuccess);
        request_user()
    }, [])

    const request_user = () => {
        localStorage.removeItem("user")
        window.location.href = '/login'

    }
    
    const endAnimatiion = () => {
        player.current.setSeeker(120, false)
    }


    const { t } = useTranslation()

    return (
        <div className='email_verify'>
            <div className='container'>
                <div className='email_verify_loader'>
                    <Player
                        onEvent={event => {
                            if (event === 'complete') endAnimatiion()

                        }}
                        autoplay
                        direction={120}
                        ref={player}
                        src="https://assets4.lottiefiles.com/packages/lf20_bx4oomul.json"
                        style={{ height: '150px', width: '150px' }}
                    >
                        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
                </div>
                <p className='verify_email_text'>{ t("alerts.alert_seller") }</p>
                <p className='continue_browsing'>
                    <Link to='/login'><BsArrowLeft />{ t("alerts.login") }</Link>
                </p>
            </div>
        </div>
    )
}

export default SellerAccountSuccess